
/*================= TWENTY TWENTY + THEME ==============*/

      .twentytwenty-after-label:before {
          content: "Σκούρο Θέμα" !important;
      }

     .twentytwenty-before-label:before {
         content: "Λευκό Θέμα" !important;
     }

    .twentytwenty-handle {
      width: 45px !important;
      border: unset !important;
      background: rgba(0, 158, 246, 0.9);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#009ef6",endColorstr="#0000ff",GradientType=1);
      }

      .twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after {
        width: 3px !important;
      }

      .twentytwenty-horizontal .twentytwenty-handle:before {
        box-shadow: 0 3px 0 rgba(1, 116, 248, 0.65), 0px 0px 12px rgba(51, 51, 51, 0.5);
      }

      .twentytwenty-horizontal .twentytwenty-handle:after {
          box-shadow: 0 -3px 0 rgba(1, 116, 248, 0.65), 0px 0px 12px rgba(51, 51, 51, 0.5);
      }

      .twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after, .twentytwenty-vertical .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after {
          background: rgba(1, 116, 248, 0.65) !important;
      }

      .image_comparison {
          margin-top: 15px;
          // box-shadow: 0 0 10px #eee;
          border: 1px solid #eeee;

              .twentytwenty-right-arrow { padding: 0; }
              .twentytwenty-left-arrow { padding: 0; }
      }


/*================= TWENTY TWENTY + THEME END ==============*/

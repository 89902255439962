
/* Preloader */

.ctn-preloader {
    -webkit-align-items: center;
            align-items: center;
    cursor: default;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 9000;

    .animation-preloader {
//        position: absolute;
        z-index: 1000;
        .spinner{
            -webkit-animation: spinner 1s infinite linear;
                    animation: spinner 1s infinite linear;
            border-radius: 50%;
            border: 3px solid rgba(0, 0, 0, 0.2);
            border-top-color: #00f;
            height: 9em;
            margin: 0 auto 3.5em auto;
            width: 9em;
        }
        .txt-loading{
            font: bold 5em $harabara;
            text-align: center;
            -webkit-user-select: none;
               -moz-user-select: none;
                -ms-user-select: none;
                    user-select: none;
            .letters-loading{
                // color: rgba(0, 0, 0, 0.2);
                color: rgba(0, 0, 255, 0.08);
                position: relative;
                &:before{
                    -webkit-animation: letters-loading 4s infinite;
                            animation: letters-loading 4s infinite;
                    color: #000000;
                    content: attr(data-text-preloader);
                    left: 0;
                    opacity: 0;
                    font-family: $harabara;
                    position: absolute;
                    top: -3px;
                    -webkit-transform: rotateY(-90deg);
                            transform: rotateY(-90deg);
                }
                &:nth-child(2){
                    &:before{
                        -webkit-animation-delay: 0.2s;
                                animation-delay: 0.2s;
                    }
                }
                &:nth-child(3){
                    &:before{
                        -webkit-animation-delay: 0.4s;
                                animation-delay: 0.4s;
                    }
                }
                &:nth-child(4){
                    &:before{
                        -webkit-animation-delay: 0.6s;
                                animation-delay: 0.6s;
                    }
                }
                &:nth-child(5){
                    &:before{
                        -webkit-animation-delay: 0.8s;
                                animation-delay: 0.8s;
                    }
                }
                &:nth-child(6){
                    &:before{
                        -webkit-animation-delay: 1s;
                                animation-delay: 1s;
                    }
                }
                &:nth-child(7){
                    &:before{
                        -webkit-animation-delay: 1.2s;
                                animation-delay: 1.2s;
                    }
                }
                &:nth-child(8){
                    &:before{
                        -webkit-animation-delay: 1.4s;
                                animation-delay: 1.4s;
                    }
                }
                &:nth-child(9){
                    &:before{
                        -webkit-animation-delay: 1.6s;
                                animation-delay: 1.6s;
                    }
                }
                &:nth-child(10){
                    &:before{
                        -webkit-animation-delay: 1.8s;
                                animation-delay: 1.8s;
                    }
                }
                &:nth-child(11){
                    &:before{
                        -webkit-animation-delay: 2s;
                                animation-delay: 2s;
                    }
                }
            }
        }
    }
    &.dark{
        .animation-preloader{
            .spinner{
                border-color: rgba(255, 255, 255, 0.2);
                border-top-color: #fff;

            }
            .txt-loading{
                .letters-loading{
                    color: rgba(255, 255, 255, 0.2);
                    &:before{
                        color: #fff;
                    }
                }
            }
        }
    }
    p{
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        letter-spacing: 8px;
        color: #3b3b3b;
    }
    .loader{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-size: 0;
        z-index: 1;
        pointer-events: none;
        .row{
            height: 100%;
        }
        .loader-section{
            padding: 0px;
            .bg{
                background-color: #ffffff;
                height: 100%;
                left: 0;
                width: 100%;
                transition: all 800ms cubic-bezier(0.77, 0, 0.175, 1);
            }
        }
        &.dark_bg{
            .loader-section{
                .bg{
                    background: #111339;
                }
            }
        }
    }
    &.loaded{
        .animation-preloader{
            opacity: 0;
            transition: 0.3s ease-out;
        }
        .loader-section {
            .bg{
                width: 0;
                transition: 0.7s 0.3s allcubic-bezier(0.1, 0.1, 0.1, 1);
            }
        }
    }
}

@-webkit-keyframes spinner {
    to {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@keyframes spinner {
    to {
        -webkit-transform: rotateZ(360deg);
                transform: rotateZ(360deg);
    }
}

@-webkit-keyframes letters-loading {
    0%,
    75%,
    100% {
        opacity: 0;
        -webkit-transform: rotateY(-90deg);
                transform: rotateY(-90deg);
    }

    25%,
    50% {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
}

@keyframes letters-loading {
    0%,
    75%,
    100% {
        opacity: 0;
        -webkit-transform: rotateY(-90deg);
                transform: rotateY(-90deg);
    }

    25%,
    50% {
        opacity: 1;
        -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
    }
}

@media screen and (max-width: 767px) {
    .ctn-preloader .animation-preloader .spinner {
        height: 8em;
        width: 8em;
    }
    .ctn-preloader .animation-preloader .txt-loading {
        font: bold 3.5em $pop;
    }
}

@media screen and (max-width: 500px) {
    .ctn-preloader .animation-preloader .spinner {
        height: 7em;
        width: 7em;
    }
    .ctn-preloader .animation-preloader .txt-loading {
        font: bold 2em $pop;
    }
}

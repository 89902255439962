
/*=== fonts ====*/
$pop: 'Poppins', sans-serif;
$harabara: 'harbara', sans-serif;

@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900');

@font-face {
  font-family: 'harbara';
  src: url('../fonts/harbara/harbara.eot');
  src: url('../fonts/harbara/harbara.eot?#iefix') format('embedded-opentype'),
       url('../fonts/harbara/harbara.ttf') format('truetype'),
       url('../fonts/harbara/harbara.woff') format('woff'),
       url('../fonts/harbara/harbara.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

// h1,h2,h3,h4,h5,h6 { font-family: 'harbara', sans-serif; }

/*===== color =====*/
$h_dk:#051441;
$p-color:#677294;

$white: #fff;
$black: #000;
$logo_black: #0d0d0d;
$basic_blue: #00f;
$basic_blue_lite: rgba(0, 0, 255, 0.75);
$basic_blue_dark: #0101bd;
$violet: #5e2ced;
$dark_violet: #5524e0;

// Mixins
@mixin transition($property: all, $duration: 400ms, $animate: linear, $delay:0s){
    transition: $property $duration $animate $delay;
}

// Placeholder Mixins

@mixin placeholder {
    &.placeholder { @content; }
    &:-moz-placeholder { @content; }
    &::-moz-placeholder { @content; }
    &::-webkit-input-placeholder { @content; }
}
@mixin gradient {
 background: #00f;
 background: linear-gradient(-18deg, #00f -13%, #009ef6 95%);
}

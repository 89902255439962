
// #burger {
//     .hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
//         background-color: #f00 !important;
//   }
// }

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before {
    width: 45px !important;
    height: 3px !important;

  }

  .hamburger-box {
    margin-top: 1rem  !important;
}

/*Modal-Box*/
.resposive_menu_wrapper {
    display: none;
    position: fixed;
    z-index: 1;
    // padding:0.5%;
    padding: 1%;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    #burger2 .hamburger-box { margin-top: unset !important; }
}

.resposive_menu_content {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;

    margin: auto;
    padding: 0;
    width: 100%;
    height: 98.5vh;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s;
    // background-color: $basic_blue;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);

    &:before {
      content: "";
      background: linear-gradient(-45deg, rgba(94, 44, 237, 0.90), rgba(35, 166, 213, 0.90), rgba(0, 0, 255, 0.90));
      background-size: 400% 400%;
      -webkit-animation: gradientBG 30s ease infinite;
      animation: gradientBG 30s ease infinite;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

  //     &:before {
  //       content: "";
  //       position: absolute;
  //       background: url(../img/home4/dots.svg);
  //       width: 100%;
  //       background-size: cover;
  //       height: 100%;
  //       -webkit-filter: saturate(0.5);
  //       filter: inherit;
  //       /* -webkit-filter: opacity(0.1); */
  //       /* filter: opacity(0.1); */
  //       mix-blend-mode: screen;
  // }
}

@-webkit-keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

@keyframes gradientBG {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.resposive_menu_header {
    padding: 2px 16px;
    background-color: $basic_blue;
    border-bottom: none;
    height:35vh;
}



.resposive_menu_footer {
    padding: 2px 16px;
    // background-color: $basic_blue;
    color: black;
    border-top: none;
    text-align: center;
}

.resposive_menu_footer hr {
    margin-top: 20vh;
}

.resposive_menu_footer>p {
  color: #468966
}

.resposive_menu_footer h3 {
    text-transform: none;
    font-size:4vh;

}

.close {
    color: #468966;
    float: left;
    font-size: 5em;
    font-weight: bold;
    opacity: 1;
}

.close:hover,
.close:focus {
    color: #B64926;
    text-decoration: none;
    cursor: pointer;
}

@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(.3, .3, .3);
        transform: scale3d(.3, .3, .3);
    }

    50% {
        opacity: 1;
    }
}

.zoomIn {
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
    -webkit-animation-name: zoomOut;
    animation-name: zoomOut;
}

.animated {
    -webkit-animation-duration: .3s;
    animation-duration: .3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}


/*Navigation Inner Styling*/

.nav {

    ul {
    clear: both;
    width: 100%;
    // border-right: 1px solid rgba(0, 84, 165, 0.45);
    padding-left: unset;
    text-align: center;

    li {
      display: block;

      a {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 1.6rem;
        letter-spacing: -0.05em;
        color: #fff;
        letter-spacing: 6px;
        line-height: 3;
        transition: all .4s ease-out;

       &:hover {
           text-decoration: none;
           color: $basic_blue_dark;
           transition: all .4s ease-out;
       } //hover

     } //a

   } //li

  }
}


.btn_inside_wrapper {
  text-align: right;
  padding: 10px;
}

// /*Mobile Optimization*/
// /* Portrait Tablet to Landscape and Desktop */
// @media (min-width:768px) and (max-width:979px) {
//
//     .nav ul li{
//         display: block;
//         height: 45px;
//     }
//
//     .nav ul {
//         clear: both;
//         width: 100%;
//         text-align: center;
//         padding: 0;
//     }
//
//     .nav ul li a {
//         padding: 0;
//         text-transform: uppercase;
//         font-weight: 700;
//         font-size: 6vw;
//         letter-spacing: -0.05em;
//     }
// }
//
// /* Landscape-Phone to Portrait Tablet*/
// @media (max-width:767px) {
//
//     .nav ul li{
//         display: block;
//         height:38px;
//     }
//
//     .nav ul {
//         clear: both;
//         width: 100%;
//         text-align: center;
//         padding: 0;
//     }
//
//     .nav ul li a {
//         padding: 0;
//         text-transform: uppercase;
//         font-weight: 700;
//         font-size: 6vw;
//         letter-spacing: -0.05em;
//     }
// }
//
// /*Landscape-Phone Downwards*/
// @media (max-width: 480px) {
//
//         .nav ul li{
//             display: block;
//             height:38px;
//         }
//
//         .nav ul {
//             clear: both;
//             width: 100%;
//             text-align: center;
//             padding: 0;
//         }
//
//         .nav ul li a {
//             padding: 0;
//             text-transform: uppercase;
//             font-weight: 700;
//             font-size: 10vw;
//             letter-spacing: -0.05em;
//         }
//   }

.resposive_menu_body.nav {
  -webkit-align-items: center;
  align-items: center;
}

.header_area.navbar_fixed {

    #burger .hamburger-inner, #burger .hamburger-inner:after, #burger .hamburger-inner:before {
    background-color: $basic_blue !important;
  }

  #burger2 .hamburger-inner:after, #burger2 .hamburger-inner:before {
    background-color: $white !important;
  }
}

.header_area.navbar_fixed {
      .hamburger_index {
         #burger .hamburger-inner:after, #burger .hamburger-inner:before {
        background-color: $white !important;
      }
      #burger .hamburger-inner {
        background-color: transparent !important;
      }
    }
  }

  .hamburger { z-index: 2 !important; }


.explore_app_details:before {
    content: "";
    position: absolute;
    background: url(../images/dots.svg);
    width: 100%;
    height: 100%;
    -webkit-filter: saturate(0.5);
    filter: saturate(0.5);
    -webkit-filter: opacity(0.1);
    filter: opacity(0.1);
}

#burger {
    display: none;
}

.header_area.hamburger_index #burger {
  z-index: 9;
}

.responsive_menu_info {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: row;
  margin-top: 0;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 2rem 0;
  border-top: 1px solid #0763bd;

  a {
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;

    img {
      -webkit-filter: saturate(0) brightness(100);
      filter: saturate(0) brightness(100);
      margin-bottom: 1rem;
      width: 60px;
      margin-right: 10px;
    }
  }

  p {
    color: #fff;
    text-transform: uppercase;
    span {
      a {
        font-weight: bold;
      }
    }
  }



}


/*============== header_area css ==============*/

.custom_container.pages_container .menu > .nav-item > .nav-link {
    color: #ffffff;
    font:600 17px/22px "Poppins", sans-serif;
  }



  .custom_container.pages_container .menu > .nav-item > .nav-link:before {
    content: "";
    height: 2px;
    background: #fff;
}

.menu_four .custom_container.pages_container .btn_get:hover {
    background: #eee;
    border-color: transparent;
    color: #5f28fb;
}


.header_area {
    position: fixed;
    width: 100%;
    left: 0;
    z-index: 20;
    top: 0;

    .navbar-brand {
      img {
        width: 200px;
      }
    }
}
.menu_one{
    padding: 0px;
    .btn_get{
        margin-left: 40px;
        margin-top: 11px;
    }
}
.sticky_logo{
    img{
        & + img{
            display: none;
        }
    }
}
.mobile_btn{
    @media(min-width:992px){
        visibility: hidden;
        display: none;
    }
}
//.hidden-sm{
//    @media(max-width:991px){
//        visibility: hidden;
//        display: none;
//    }
//}
.menu{
    >.nav-item{
        position: relative;
        padding-bottom: 35px;
        padding-top: 48px;
       > .nav-link{
            font: 600 17px/18px $pop;
            color: #000;
            padding: 0px;
            position: relative;

            color: #051441;
            padding: 0px;
            transition: color 0.3s ease 0s;
            position: relative;
            text-transform: uppercase;
            letter-spacing: 2px;


            &:before{
                content: "";
                width: 0;
                height: 3px;
                background: #5e2ced;
                position: absolute;
                bottom: 0;
                left: auto;
                right: 0;
                z-index: -1;
                transition: all 0.2s linear;
            }
            &:after{
                display: none;
            }
        }
        &.submenu{
            .dropdown-menu{
                margin: 0px;
                border: 0px;
                margin: 0px;
                border-radius: 0px;
                left: -40px;
                min-width: 200px;
                padding: 0px;
                background: transparent;
                box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
                @media(min-width: 992px){
                    -webkit-transform: translateY(20px);
                            transform: translateY(20px);
                    transition: all 0.3s ease-in;
                    opacity: 0;
                    visibility: hidden;
                    display: block;
                }
                .nav-item{
                    display: block;
                    width: 100%;
                    margin-right: 0px;
                    padding: 0px 40px;
                    background: #fff;
                    position: relative;
                    .nav-link{
                        padding: 0px;
                        white-space: nowrap;
                        font: 400 14px/34px $pop;
                        color: $basic_blue;
                        transition: color 0.2s linear;
                        &:after{
                            display: none;
                        }
                        span.arrow_carrot-right {
                            right: 20px;
                            position: absolute;
                            line-height: 30px;
                            font-size: 18px;
                        }
                    }
                    &:hover,&:focus,&.active{
                        > .nav-link{
                            color: $basic_blue;
                        }
                    }
                    &:first-child{
                        padding-top: 25px;
                        > .dropdown-menu{
                            @media (min-width: 992px){
                                top: 0;
                            }
                        }
                    }
                    &:last-child{
                        padding-bottom: 30px;
                        margin-bottom: -8px;
                    }

                    > .dropdown-menu{
                        transition: -webkit-transform 0.3s ease-in;
                        transition: transform 0.3s ease-in;
                        transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
                        padding: 0px;
                        &:before{
                            display: none;
                        }
                        @media (min-width: 992px){
                            position: absolute;
                            left: 100%;
                            top: -25px;
                            opacity: 0;
                            display: block;
                            visibility: hidden;
                            -webkit-transform: translateY(20px);
                                    transform: translateY(20px);
                            transition: all 0.3s ease-in;
                        }
                    }
                }
                &.mega_menu_three{
                    > .nav-item{
                        padding: 0px;
                        > .dropdown-menu{
                            left: 0;
                            position: relative;
                            -webkit-transform: translateY(0);
                                    transform: translateY(0);
                            .nav-item{
                                padding: 20px 45px;
                                border: 1px solid transparent;
                                transition: all .15s linear;
                                .nav-link{
                                    .navdropdown_link{
                                        display: -webkit-flex;
                                        display: flex;
                                        .navdropdown_icon{
                                            margin-right: 25px;
                                        }
                                        .navdropdown_content{
                                            h5{
                                                font: 500 16px $pop;
                                                color: #13112d;
                                                margin-bottom: 0px;
                                            }
                                            p{
                                                margin-bottom: 0;
                                                padding-top: 5px;
                                                font-size: 15px;
                                                line-height: 20px;
                                                color: #677294;
                                            }
                                        }
                                    }
                                }
                                &.nav_download_btn{
                                    border-top-color: #f2f0f7;
                                    padding: 15px 45px;
                                    .nav-link{
                                        .navdropdown_link{
                                            -webkit-align-items: center;
                                                    align-items: center;
                                            -webkit-justify-content: center;
                                                    justify-content: center;
                                            .navdropdown_icon{
                                                margin-right: 10px;
                                            }
                                        }
                                    }
                                }
                                &:hover{
                                    -webkit-transform: scale(1.05);
                                            transform: scale(1.05);
                                    border-color: #f2f0f7;
                                    background-color: rgb(255, 255, 255);
                                    box-shadow: 0px 20px 30px 0px rgba(12, 0, 46, 0.1),0px -1px 0px 0px rgba(243, 241, 248, 0.004);
                                }
                            }
                        }
                    }

                }
            }
            &.mega_menu{
                position: static;
                .mega_menu_inner{
                    min-width: 200px;
                    list-style: none;
                    position: absolute;
                    box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
                    left: 0px;
                    right: 0px;
                    transition: all 300ms linear 0s;
                    background: #fff;
                    @media(min-width: 992px){
                        -webkit-transform: translateY(20px);
                        transform: translateY(20px);
                        transition: all 0.3s ease-in;
                        top: 100%;
                        opacity: 0;
                        visibility: hidden;
                        display: block;
                    }
                    > .dropdown-menu{
                        position: relative;
                        max-width: 1170px;
                        margin: 0 auto;
                        left: 0;
                        background: transparent;
                        box-shadow: none;
                        padding: 0px;
                        @media (min-width: 992px){
                            display: -webkit-flex;
                            display: flex;
                            -webkit-flex-wrap: wrap;
                                    flex-wrap: wrap;
                        }
                        > .nav-item{
                            padding-top: 40px;
                            padding-bottom: 40px;
                            margin-bottom: 0px;
                            width: 25%;
                            > .nav-link{
                                font: 500 18px $pop;
                                color: #13112d;
                            }
                            .dropdown-menu{
                                left: 0;
                                position: relative;
                                -webkit-transform: translateY(0);
                                        transform: translateY(0);
                                box-shadow: none;
                                padding: 0px;
                                top: 0;
                                > .nav-item{
                                    padding-left: 0px;
                                    padding-right: 0px;
                                    white-space: normal;
                                    &:first-child{
                                        padding-top: 20px;
                                    }
                                    &:last-child{
                                        padding-bottom: 0px;
                                        margin-bottom: 0px;
                                    }
                                }
                            }
                        }
                    }
                }
                &.mega_menu_two{
                    .mega_menu_inner {
                        .dropdown-menu {
                            left: 0;
                            @media (min-width: 992px) {
                                padding: 20px 0px;
                            }
                            .mCSB_scrollTools {
                                width: 0px;
                            }
                            .mCSB_container {
                                margin: 0;
                                display: -webkit-flex;
                                display: flex;
                                -webkit-flex-wrap: wrap;
                                        flex-wrap: wrap;
                            }
                            .mCustomScrollBox {
                                width: 100%;
                            }
                            .nav-item {
                                padding: 0px;
                                margin-bottom: 0;
                                width: 100%;
                                .dropdown-menu{
                                    left: 0;
                                    display: -webkit-flex;
                                    display: flex;
                                    -webkit-flex-wrap: wrap;
                                            flex-wrap: wrap;
                                    margin-left: -20px;
                                    margin-right: -20px;
                                    @media (max-height:980px) and (min-width:992px) {
                                        max-height: 750px;
                                    }
                                    .nav-item{
                                        width: 25%;
                                        padding: 15px 20px;
                                        margin-bottom: 0;
                                    }
                                }
                                .item {
                                    text-align: center;
                                    .rebon_tap {
                                        font: 500 12px $pop;
                                        color: #1bcb1f;
                                        background: #fff;
                                        display: block;
                                        position: absolute;
                                        top: -7px;
                                        left: -25px;
                                        padding: 15px 22px 5px;
                                        text-transform: uppercase;
                                        -webkit-transform: rotate(-45deg);
                                                transform: rotate(-45deg);
                                    }

                                    .img {
                                        box-shadow: 0px 4px 8px 0px rgba(12, 0, 46, 0.06);
                                        position: relative;
                                        margin-top: 0;
                                        display: block;
                                        overflow: hidden;

                                        &:before {
                                            content: "";
                                            width: 100%;
                                            height: 100%;
                                            position: absolute;
                                            background: #13112d;
                                            left: 0;
                                            top: 0;
                                            opacity: 0;
                                            transition: opacity 0.3s linear;
                                        }

                                        img {
                                            width: 100%;
                                            max-width: 100%;
                                        }

                                        &.box_none {
                                            box-shadow: none;
                                        }
                                    }

                                    .text {
                                        font: 500 16px/22px $pop;
                                        color: #13112d;
                                        display: block;
                                        margin-top: 20px;

                                        &:hover {
                                            color: $basic_blue;
                                        }
                                    }

                                    &:hover {
                                        .img {
                                            &:before {
                                                opacity: 0.50;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &:hover{
                    @media(min-width: 992px){
                        .dropdown-menu{
                            > .nav-item{
                                > .dropdown-menu{
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                        .mega_menu_inner{
                            -webkit-transform: translateY(0);
                                    transform: translateY(0);
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
        &:nth-last-child(-n+2){
            &.submenu{
                .dropdown-menu{
                    left: auto;
                    right: 0;
                    .nav-item{
                        > .dropdown-menu{
                            left: auto;
                            right: 100%;
                        }
                    }
                }
                &.mega_menu{
                    .dropdown-menu{
                        left: 0;
                        right: auto;
                        .nav-item{
                            > .dropdown-menu{
                                left: 0;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
        & + .nav-item{
            margin-left: 18px;
        }
        &.active{
            .nav-link{
                // color: $basic_blue;
                color: #000;
                &:before {
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
        }
        &:hover{
            .nav-link{
                color: $black;
                &:before{
                    width: 100%;
                    left: 0;
                    right: auto;
                }
            }
            @media(min-width: 992px){
                .dropdown-menu{
                    opacity: 1;
                    -webkit-transform: translateY(0);
                            transform: translateY(0);
                    visibility: visible;
                    &.mega_menu_three{
                        .nav-item{
                            .dropdown-menu{
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                   > .nav-item{
                        &:hover{
                            @media (min-width: 992px){
                                .dropdown-menu{
                                    -webkit-transform: scaleY(1);
                                            transform: scaleY(1);
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.navbar{
    .search_cart{
        .search,.shpping-cart{
            a{
                font-size: 16px;
                line-height: 18px;
                color: #282835;
                margin-left: 12px;
            }
        }
        &.w_menu{
            .search,.shpping-cart{
                a{
                    color: #fff;
                }
            }
            .search{
                a.nav-link{
                    &:before{
                        background: #fff;
                    }
                }
            }
        }
        .search{
            display: inline-block;
            a.nav-link{
                font-size: 13px;
                line-height: 19px;
                position: relative;
                margin-left: 32px;
                &:before{
                    width: 1px !important;
                    height: 12px;
                    left: -14px;
                    top: 3px;
                    background: #13112d;
                }
            }
        }
        .shpping-cart{
            display: inline-block;
            margin-left: 0px;
            margin-right: 0px;
            .num{
                position: absolute;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #6754e2;
                color: #fff;
                font-size: 11px;
                display: block;
                position: absolute;
                top: -8px;
                right: -9px;
                line-height: 16px;
                text-align: center;
            }
            .dropdown-menu{
                padding: 5px 0px 0px;
                min-width: 350px;
                width: 100%;
                position: absolute;
                li{
                    background: #fff;
                    &:first-child{
                        padding-bottom: 0px;
                    }
                }
                .cart-single-item{
                    display: -webkit-flex;
                    display: flex;
                    -webkit-flex-wrap: wrap;
                            flex-wrap: wrap;
                    padding: 30px;
                    a{
                        color: #333;
                        margin-left: 0;
                    }
                    .cart-img{
                        display: block;
                        width: 30%;
                        transition: all 0.3s linear;
                    }
                    .cart-content{
                        width: 60%;
                        padding-left: 15px;
                        -webkit-align-self: center;
                                align-self: center;
                        .cart-title{
                            font-size: 15px;
                            font-weight: 500;
                            line-height: 22px;
                            font-family: $pop;
                            margin-bottom: 10px;
                            transition: color 0.3s linear;
                        }
                       p{
                            font-size: 14px;
                            line-height: 22px;
                            font-family: $pop;
                            color: #333;
                            font-weight: 300;
                            margin-bottom: 0px;
                            del{
                                color: #919191;
                            }
                       }
                    }
                    .cart-remove{
                        width: 10%;
                        text-align: right;
                        -webkit-align-self: center;
                                align-self: center;
                        a{
                            font-size: 14px;
                            color: #333;
                            &:hover{
                                color:#6754e2;
                            }
                        }
                    }
                    &:hover{
                        .cart-img{
                            opacity: 0.5;
                        }
                        .cart-title{
                            a{
                                color:#6754e2;
                            }
                        }
                    }
                }
                .cart_f{
                    padding: 25px 30px 30px;
                    background: #f9f9f9;
                    .total{
                        font-size: 14px;
                        color: #676b75;
                        .p-total{
                            float: right;
                            font-size: 20px;
                            color: #333333;
                            font-weight: 500;
                        }
                    }
                    .cart-button{
                        .get_btn{
                            font-size: 14px;
                            text-transform: capitalize;
                            border-radius: 4px;
                            margin: 0px;
                            line-height: 40px;
                            padding: 0px 32px;
                            background: #6754e2;
                            border: 1px solid #6754e2;
                            color: #fff;
                            transition: all 0.3s linear;
                            &:hover{
                                background: transparent;
                                color: #6754e2;
                            }
                            & + .get_btn{
                                margin-left: 10px;
                                background: transparent;
                                color: #6754e2;
                                &:hover{
                                    background: #6754e2;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.menu_two{
    padding: 0px;
    .w_menu{
        > .nav-item{
            .nav-link{
                &:before{
                    background: #a5d5f5;
                }
            }
            &.submenu{
                .dropdown-menu{
                    .nav-item{
                        &:hover,&:focus,&.active{
                            > .nav-link{
                                color: #00aff0;
                            }
                        }
                    }
                }
                &.mega_menu{
                    > .dropdown-menu{
                        > .nav-item{
                            > .nav-link{
                                color: #13112d;
                            }
                        }
                    }
                }
            }
        }
    }
    .menu_toggle{
        .hamburger-cross span,.hamburger span{
            background: #fff;
        }
    }
}
.w_menu{
    .nav-item{
        .nav-link{
            color: #fff;
            &:before{
                background: #fff;
            }
        }
        &:hover,&.active{
            .nav-link{
                color: #fff;
            }
        }
    }
}
.nav_right_btn{
    .login_btn{
        font: 500 15px $pop;
        padding: 13px 32px;
        min-width: 120px;
        display: inline-block;
        text-align: center;
        color: #fff;
        transition: all 0.2s linear;
        border-top-left-radius: 45px;
        border-bottom-left-radius:45px;
        border-top-right-radius:45px;
        border: 1px solid transparent;
        &:hover,&.active{
            background: #fff;
            color: #00aff0;
            box-shadow: 0px 20px 24px 0px rgba(0, 11, 40, 0.1);
        }
    }
    @media (max-width:991px){
        display: none;
    }
}


.menu_four{
    .btn_get{
        // border-color: #fff;
        padding: 0px 30px;
        min-width: 120px;
        text-align: center;
        background: #fff;
        text-transform: uppercase;
        // background: #2930ff;
        color: #000;
        // padding: 10px 20px;
        // font-size: 15px;
        border: 1px solid transparent;
        border-radius: 25px;

        &:hover{
            background: $basic_blue;
            border-color: $basic_blue;
            color: #fff;
        }
    }
    .btn_get_radious{
        background: transparent;
        &:hover{
            background: #fff;
        }
    }
    .menu_toggle{
        .hamburger,.hamburger-cross{
            span{
                background: #fff;
            }
        }
    }
}

.menu_five{
    border-radius: 0px;
    .menu{
        > .nav-item{
            .nav-link{
                color: #fff;
            }
            &.submenu{
                .dropdown-menu{
                    .nav-item{
                        &:hover,&:focus,&.active{
                            > .nav-link{
                                color: #3d64f4;
                            }
                        }
                    }
                }
                &.mega_menu{
                    > .dropdown-menu{
                        > .nav-item{
                            > .nav-link{
                                color: #051441;
                            }
                        }
                    }
                }
            }
        }
    }
    .btn_get{
        background: transparent;
        color: #fff;
        border:1px solid #fff;
        line-height: 44px;
        padding: 0px 28px;
        min-width: 120px;
        text-align: center;
        &:hover{
            background: #fff;
            color: #3d64f4;
            border-color: #fff;
        }
    }
    .menu_toggle{
        .hamburger,.hamburger-cross{
            span{
                background: #fff;
            }
        }
    }
}

.menu_six{
    padding: 0px;
    .menu{
        > .nav-item{
            .nav-link{
                color: #fff;
            }
            &:hover,&.active{
                .nav-link{
                    color: #3d57f4;
                    &:before{
                        background: #3d57f4;
                    }
                }
            }
            &.submenu{
                .dropdown-menu{
                    .nav-item{
                        &:hover,&:focus,&.active{
                            > .nav-link{
                                color: #3d57f4;
                            }
                        }
                    }
                }
                &.mega_menu{
                    > .dropdown-menu{
                        > .nav-item{
                            > .nav-link{
                                color: #051441;
                            }
                        }
                    }
                }
            }
            &:nth-last-child(-n+2).submenu .dropdown-menu{
                left: -40px;
                right: auto;
            }
        }
    }
    .menu_toggle{
        .hamburger-cross,.hamburger{
            span{
                background: #fff;
            }
        }
    }
    .btn_get{
        border: 1px solid #fff;
        line-height: 43px;
        color: #fff;
        &:hover{
            background: #3d57f4;
            border-color: #3d57f4;
        }
    }
}

.menu_seven{
    padding: 0px;
    .menu{
        .nav-item{
            .nav-link{
                color: #fff;
            }
            &.active,&:hover{
                .nav-link{
                    &:before{
                        background: #fff;
                    }
                }
            }
            &.submenu{
                .dropdown-menu{
                    .nav-item{
                        &:hover,&:focus,&.active{
                            > .nav-link{
                                color: #4069eb;
                            }
                        }
                    }
                }
                &.mega_menu{
                    > .dropdown-menu{
                        > .nav-item{
                            > .nav-link{
                                color: #051441;
                            }
                        }
                    }
                }
            }
        }

    }
    .menu_toggle{
        .hamburger-cross,.hamburger{
            span{
                background: #fff;
            }
        }
    }
    .btn_get{
        background: transparent;
        &:hover{
            background: #fff;
            color: #4069eb;
        }
    }
}

.menu_eight{
    .menu{
        .nav-item{
            .nav-link{
            }
            &.active,&:hover{
                .nav-link{
                    &:before{
                        background: #fff;
                    }
                }
            }
            &.submenu{
                .dropdown-menu{
                    .nav-item{
                        &:hover,&:focus,&.active{
                            > .nav-link{
                                color: #23b1fe;
                            }
                        }
                    }
                }
                &.mega_menu{
                    > .dropdown-menu{
                        > .nav-item{
                            > .nav-link{
                                color: #051441;
                            }
                        }
                    }
                }
            }
        }
    }
    .saas_btn{
        border-radius: 4px;
        background-color: #fff;
        border:1px solid #fff;
        color: #23b1fe;
    }
    .menu_toggle{
        .hamburger-cross,.hamburger{
            span{
                background: #fff;
            }
        }
    }
}

.menu_nine{
    .menu{
        > .nav-item{
            &.submenu{
                .dropdown-menu{
                    .nav-item{
                        &:hover,&:focus,&.active{
                            > .nav-link{
                                color: #5f51fb;
                            }
                        }
                    }
                }
                &.mega_menu{
                    > .dropdown-menu{
                        > .nav-item{
                            > .nav-link{

                            }
                        }
                    }
                }
            }
        }
    }
    .menu_toggle{
        .hamburger-cross span,.hamburger span{
            background: #fff;
        }
    }
    .btn_get{
        border-radius: 45px;
        line-height: 40px;
        font-size: 15px;
        margin-left: 35px;
    }
}

.btn_get_radious{
    border-radius: 45px;
    border-width: 1px;
    color: #fff;
    background: #fff;
    box-shadow: none;
    padding: 0px 28px;
    line-height: 45px;
    &:before{
        background: #5846ed;
    }
    &:hover{
        color: #5846ed;
    }
}
/*============== header_area css ==============*/


/*========= hamburger menu css ========*/
.navbar-toggler{
    padding: 0px;
    margin-left: 20px;
    &:focus{
        outline: none;
    }
}
  .menu_toggle {
    width: 50px;
    height: 45px;
    position: relative;
    cursor: pointer;
    display: block;
    .hamburger{
        position: absolute;
        height: 100%;
        width: 100%;
        display: block;
        span{
            width: 0%;
            height: 3px;
            position: relative;
            top: 0;
            left: 0;
            margin: 10px 0;
            display: block;
            background: #5e2ced;
            border-radius: 3px;
            transition: .2s ease-in-out;
            &:nth-child(1){
                transition-delay: 0s;
            }
            &:nth-child(2){
                transition-delay: .125s;
            }
            &:nth-child(3){
                transition-delay: .2s;
            }
        }
    }
    .hamburger-cross{
        position: absolute;
        height: 100%;
        width: 100%;
        -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
        display: block;
        span{
            display: block;
            background: #5e2ced;
            border-radius: 3px;
            transition: .2s ease-in-out;
            &:nth-child(1){
              height: 100%;
              width: 3px;
              position: absolute;
              top: 0px;
              left: 24px;
              transition-delay: .3s;
            }
            &:nth-child(2){
              width: 100%;
              height: 3px;
              position: absolute;
              left: 0px;
              top: 20px;
              transition-delay: .4s;
            }
        }
    }
}
.collapsed{
    .menu_toggle{
        .hamburger{
            span{
                width: 100%;
                &:nth-child(1){
                    transition-delay: .3s;
                }
                &:nth-child(2){
                    transition-delay: .4s;
                }
                &:nth-child(3){
                    transition-delay: .5s;
                }
            }
        }
        .hamburger-cross{
            span{
                &:nth-child(1){
                    height: 0%;
                    transition-delay: 0s;
                }
                &:nth-child(2){
                    width: 0%;
                    transition-delay: .2s;
                }
            }
        }
    }
}



.header_area_two,.header_area_three{
    & + section{
        margin-top: 93px;
    }
}
.h_dark{
    background: #13112d;
}

.header_area_three{
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 2px 4px 0px rgba(12, 0, 46, 0.1);
    .menu_four{
        .pl_30{
            padding-left: 30px;
        }
        .btn_get{
            border-color:  #5e2ced;
            background: #5e2ced;
            color: #fff;
            &:hover{
                background: transparent;
                color: #5e2ced;
            }
        }
    }
    &.navbar_fixed{
        .menu_four{
            .btn_get{
                border-color:  #5e2ced !important;
            }
        }

    }
}

.header_area_four{
    .header_top{
        background: #13112d;
        a{
            transition: color 0.3s linear;
            &:hover{
                color: #5e2ced;
            }
        }
    }
    & + section{
        margin-top: 144px;
    }
}
.header_top{
    padding: 12px 0px;
}
.header_contact_info{
    margin-bottom: 0;
    li{
        display: inline-block;
        font-size: 14px;
        font-family: $pop;
        font-weight: 400;
        color: #fff;
        text-transform: capitalize;
        &:before{
            content: "";
            width: 1px;
            height: 12px;
            top: 1px;
            background: #5e5d76;
            display: inline-block;
            position: relative;
            margin: 0px 22px 0px 20px;
        }
        &:first-child{
            &:before{
                display: none;
            }
        }
        a{
            color: #c3c1da;
            font-weight: 300;
            padding-left: 5px;
        }
    }
}
.header_social_icon{
    text-align: right;
    margin-bottom: 0;
    li{
        display: inline-block;
        margin: 0px 0px 0px 15px;
        a{
            font-size: 12px;
            color: #fff;
        }
    }
}

.header_area_five{
    .menu_one{
        padding: 0px 50px;
    }
}

.header_area_six{
    .header_top{
        border-bottom: 1px solid #e5ddfc;
        a{
            &:hover{
                color: #5e2ced !important;
            }
        }
        .header_contact_info{
            li{
                color: #282835;
                a{
                    color: #677294;
                }
            }
        }
        .header_social_icon{
            li{
                a{
                    color: #282835;
                }
            }
        }
    }
    & + section{
        margin-top: 144px;
    }
}


.header_area_seven{
    .header_top{
        background: #5e2ced;
        padding: 0px;
        a{
            line-height: 50px;
            opacity: 0.7;
            transition: opacity 0.2s linear;
            &:hover{
                opacity: 1;
            }
        }
        .header_contact_info{
            li{
                i{
                    vertical-align: middle;
                }
                a{
                    color: #fff;
                }
            }
        }
        .header_social_icon {
            border-left: 1px solid #7444ff;
            padding-left: 22px;
            margin-left: 40px;
        }
        .header_top_menu{
            text-align: right;
            margin-bottom: 0px;
            li{
                display: inline-block;
                &:before{
                    content: "";
                    width: 1px;
                    height: 11px;
                    background: #8e6bf7;
                    display: inline-block;
                    vertical-align: middle;
                    margin: 0px 17px 0px 15px;
                }
                a{
                    font-size: 12px;
                    font-family: $pop;
                    font-weight: 300;
                    color: #fff;
                    transition: opacity 0.2s linear;
                }
                &:first-child{
                    &:before{
                        display: none;
                    }
                }
            }
        }
    }
    & + section{
        margin-top: 118px;
    }
}

// svg {
//   width: 0;
//   height: 0;
//   margin: 0;
//   padding: 0;
//   border: 0;
// }

/*============ sticky menu css ==============*/
.header_area {
    transition: all 0.2s linear;

    .pages_container {
      .navbar-brand {
        // img {
        //   -webkit-filter: saturate(0) brightness(100);
        //           filter: saturate(0) brightness(100);
        // }

        .white_logo {
        }
        .colored_logo {
          display: none;
        }

      }
    }

    &.navbar_fixed {
      .pages_container {
        .navbar-brand {

          .white_logo {
            display: none;
          }
          .colored_logo {
            display: block;
          }

        }
      }

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        .menu_one {
            padding: 0px;
            // transition: all 0.2s linear;
            // background-color: white;
            box-shadow: 0px 4px 6px 0px rgba(12, 0, 46, 0.1);
            box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.1);
            // border-radius: 5px;
            background-color: rgba(255, 255, 255,1);
            // background: inherit;
            //
            // -webkit-backdrop-filter: blur(5px);
            // backdrop-filter: blur(10px);


            .btn_get {
                box-shadow: none;
            }

            &.hamburger_index {
                 background-color: unset;
                 box-shadow: unset;
                 -webkit-backdrop-filter: unset;
                         backdrop-filter: unset;
                 z-index: 2;

                 .navbar-brand {
                    opacity: 0;
                  }
              }
        }

        .resposive_menu_content {
            -webkit-backdrop-filter: blur(4px);
                    backdrop-filter: blur(4px);
        }


        .pages_container {
          .navbar-brand {
            img {
              -webkit-filter: saturate(1) brightness(100%);
                      filter: saturate(1) brightness(100%);
            }
          }
        }

        .custom_container.pages_container .menu > .nav-item > .nav-link:before {
            background: #00f;
        }

        .menu > .nav-item > .nav-link { font-size: 17px; color: #000; }

        .navbar-brand {
          // img {
          //   width: 190px;
          //   transition: width .4s ease-out;
          // }
        }

        .sticky_logo {
            img{
                display: block;
                // & + img{
                //     display: block;
                // }
            }
        }

        .w_menu {
            .nav-item {
                .nav-link {
                    color: #051441;
                    &:before {
                        background: #051441;
                    }
                }
            }
        }

        .menu_pos{
            .w_menu{
                .nav-item{
                    .nav-link{
                        &:hover{
                            color: #5e53fb;
                            &:before{
                                background: #5e53fb;
                            }
                        }
                    }
                    &.active{
                        .nav-link{
                            color: #5e53fb;
                            &:before{
                                background: #5e53fb;
                            }
                        }
                    }
                }
                &.search_cart .search{
                    a.nav-link{
                        &:before{
                            background: #5e53fb;
                        }
                    }
                }
            }
        }
        .menu_two{
            .menu_toggle{
                .hamburger-cross,.hamburger{
                    span{
                        background: #051441;
                    }
                }
            }
            .nav_right_btn .login_btn{
                &.active,&:hover{
                    border-color: #00aff0;
                    color: #00aff0;
                    box-shadow: none;
                }
                &.active{
                    &:hover{
                        background: #00aff0;
                        color: #fff;
                    }
                }
            }
        }
        .menu_four{
            .btn_get{
                background-color: $basic_blue;
                // border: 2px solid $basic_blue;
                color: #fff;
                box-shadow: none;
                &:hover{
                  background-color: $basic_blue;
                  background-color: $basic_blue_lite;
                  box-shadow: none;
                  // --------------------------------------------------------------------------------
                  // example:  Um trecho de exemplo, para ilustrar a aplicação e uso de um código.
                  // --------------------------------------------------------------------------------
                }
            }
            .btn_get_radious{
                border-color: #563bd1;
                color: #563bd1;
                &:hover{
                    background: #563bd1;
                    color: #fff;
                }
            }
            .menu_toggle{
                .hamburger,.hamburger-cross{
                    span{
                        background: $basic_blue;
                    }
                }
            }
        }
        .nav_right_btn{
            .login_btn{
                color: #051441;
            }
        }
        .menu_five{
            .btn_get{
                border-color: #3d64f4;
                color: #3d64f4;
                display: inline-block;
                &:hover{
                    background: #3d64f4;
                    color: #fff;
                }
            }
            .menu_toggle{
                .hamburger-cross,.hamburger{
                    span{
                        background: #3d64f4;
                    }
                }
            }
        }
        .menu_six{
            .menu{
                > .nav-item{
                    > .nav-link{
                        color: #051441;
                    }
                    &:hover,&.active{
                        > .nav-link{
                            color: #3d57f4;
                            &:before{
                                background: #3d57f4;
                            }
                        }
                    }
                }
            }
            .menu_toggle{
                .hamburger-cross,.hamburger{
                    span{
                        background: #3d57f4;
                    }
                }
            }
            .btn_get{
                border-color: #3d57f4;
                box-shadow: none;
                color: #3d57f4;
                &:hover{
                    color: #fff;
                }
            }
        }
        .menu_seven{
            .menu{
                > .nav-item{
                    > .nav-link{
                        color: #051441;
                    }
                    &:hover,&.active{
                        > .nav-link{
                            color: #4069eb;
                            &:before{
                                background: #4069eb;
                            }
                        }
                    }
                }
            }
            .btn_get{
                border-color: #4069eb;
                color: #4069eb;
                &:hover{
                    background: #4069eb;
                    color: #fff;
                }
            }
            .menu_toggle{
                .hamburger-cross,.hamburger{
                    span{
                        background: #4069eb;
                    }
                }
            }
        }
        .menu_eight{
            .saas_btn{
                border-color: #23b1fe;
                box-shadow: none;
                &:hover{
                    background: #23b1fe;
                    color: #fff;
                }
            }
            .menu_toggle{
                .hamburger,.hamburger-cross{
                    span{
                        background: #23b1fe;
                    }
                }
            }
        }
        .menu_nine{
            .menu{
                > .nav-item{
                    &:hover,&.active{
                        > .nav-link{
                            color: #5f51fb;
                            &:before{
                                background: #5f51fb;
                            }
                        }
                    }
                }
            }
            .btn_get{
                border-color: #5f51fb;
                color: #5f51fb;
                &:hover{
                    background: #5f51fb;
                    color: #fff;
                }
            }
            .menu_toggle{
                .hamburger-cross,.hamburger{
                    span{
                        background: #5f51fb;
                    }
                }
            }
        }
        &.header_area_five{
            .menu_one{
                padding: 0px 50px;
            }
        }
        &.header_area_six{
            .header_top{
                display: none;
            }
        }




    } //navbar fixed

}


.full_header{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    padding: 40px;
    .logo{
        img{
            display: none;
            & + img{
                display: inline-block;
            }
        }
    }
    .bar_menu{
        cursor: pointer;
        span{
            width: 36px;
            height: 2px;
            background: #fff;
            display: block;
            &:not(:last-child){
                margin-bottom: 7px;
            }
            &:nth-child(2){
                width: 24px;
            }
            &:nth-child(3){
                width: 30px;
            }
        }
    }
    &.content-white{
        .logo{
            img{
                display: inline-block;
                & + img{
                    display: none;
                }
            }
        }
    }
}



.hamburger-menu-wrepper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 108;
    padding: 55px 80px;
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    transition: -webkit-transform 0.8s ease .4s;
    transition: transform 0.8s ease .4s;
    transition: transform 0.8s ease .4s, -webkit-transform 0.8s ease .4s;
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    background: rgba(5, 20, 65, 0.98);
    .nav{
        position: relative;
        z-index: 108;
        -webkit-transform: translateY(-15px);
                transform: translateY(-15px);
        opacity: 0;
        visibility: hidden;
        transition: all ease .3s .0s;
        .nav_logo{
            img{
                opacity: 1 !important;
            }
        }
        .close-menu{
            color: #fff;
        }
    }
    .animation-box{
        position: absolute;
        top: 5%;
        height: 100%;
        width: 100%;
        left: 0;
//        transform: perspective(600px) rotateX(-65deg);
        transition-timing-function: linear;
        transition-duration: .3s;
        .menu-box{
            display: table;
            height: 100%;
            width: 62%;
            margin: 0 auto;
            opacity: 0;
            transition-timing-function: ease-in-out;
            transition-duration: .3s;
        }
    }
    &.show-menu{
        z-index: 108;
        opacity: 1;
        visibility: visible;
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
        -webkit-transform-origin: 50% 0%;
                transform-origin: 50% 0%;
        transition: -webkit-transform .7s ease .2s;
        transition: transform .7s ease .2s;
        transition: transform .7s ease .2s, -webkit-transform .7s ease .2s;
        .nav{
            -webkit-transform: translateY(0px);
                    transform: translateY(0px);
            transition: opacity ease 1.4s 0.7s, -webkit-transform ease 1.4s 0.7s;
            transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s;
            transition: transform ease 1.4s 0.7s, opacity ease 1.4s 0.7s, -webkit-transform ease 1.4s 0.7s;
            opacity: 1;
            visibility: visible;
        }
        .animation-box{
            top: 0;
//            transform: perspective(600px) rotateX(0deg);
            opacity: 1;
            transition-timing-function: linear;
            transition-duration: .7s;
            .menu-box{
                opacity: 1;
                .offcanfas_menu{
                    > li{
                        a{
                            -webkit-transform: translateY(0px);
                                    transform: translateY(0px);
                            transition: opacity ease 1.4s 0.6s, -webkit-transform ease 1.4s 0.6s;
                            transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s;
                            transition: transform ease 1.4s 0.6s, opacity ease 1.4s 0.6s, -webkit-transform ease 1.4s 0.6s;
                            opacity: 1;
                            visibility: visible;
                        }
                    }
                }
            }
        }
    }
}

.navbar .offcanfas_menu{
    margin: 0px;
    display: table-cell;
    vertical-align: middle;
    width: 50%;
    > .nav-item{
        overflow: hidden;
        padding: 0px;
        margin: 0px;
        &:not(:last-child){
            margin-bottom: 18px;
        }
        .nav-link{
            font-size: 2.1rem;
            line-height: 46px;
            display: inline-block;
            color: #fff;
            font-weight: 700;
            font-family: $pop;
            -webkit-transform: translateY(45px);
                    transform: translateY(45px);
            opacity: 0;
            visibility: hidden;
            transition: all ease .3s .0s;
            display: inline-block;
            &:before{
                background: #fff;
            }
        }
        &.submenu{
            .nav-link{
                padding-right: 20px;
                &:after{
                    content: "\33";
                    font-family: eleganticons;
                    position: absolute;
                    right: 13px;
                    top: 0;
                    display: block;
                    border: 0;
                    line-height: 50px;
                    margin-left: 20px;
                }
            }
            .dropdown-menu{
                position: relative !important;
				-webkit-transform: translate3d(0px, 0px, 0px) !important;
				        transform: translate3d(0px, 0px, 0px) !important;
                display: none;
                -webkit-transform: translateY(0);
                        transform: translateY(0);
                opacity: 1;
                visibility: visible;
                transition:all 0s linear;
                z-index: 0;
                background: transparent;
                padding-left: 25px;
                padding-top: 20px;
                padding-bottom: 0;
                max-height: none !important;
                left: 0;
                >.nav-item{
                    background: transparent;
                    padding: 0px;
                    >.nav-link{
                        color: #fff;
                        font-size: 16px;
                        line-height: 31px;
                    }
                    &:hover,&.active{
                        .nav-link{
                            color: #5f28fb;
                        }
                    }
                }
            }
            &.active{
                .nav-link{
                    color: #5f28fb;
                    &:before{
                        background: #5f28fb;
                    }
                }
            }
            &.show{
                .nav-link{
                    color: #5f28fb;
                    &:before{
                        width: 100%;
                        background: #5f28fb;
                    }
                }
                .dropdown-menu{
                    position: relative !important;
				    -webkit-transform: translate3d(0px, 0px, 0px) !important;
				            transform: translate3d(0px, 0px, 0px) !important;
                }
            }
        }
    }
}

.header_footer{
    display: table-footer-group;
    padding-bottom: 80px;
    h5{
        font-size: 16px;
        color: #fff;
    }
    ul{
        margin-bottom: 40px;
        li{
            display: inline-block;
            margin-right: 6px;
            a{
                width: 44px;
                height: 44px;
                border: 1px solid #656c82;
                border-radius: 50%;
                text-align: center;
                font-size: 14px;
                line-height: 44px;
                color: #fff;
                display: block;
                transition: all 0.3s linear;
                &:hover{
                    background: #fff;
                    border-color: #fff;
                    color: #5f28fb;
                }
            }
        }
    }
    p{
        font-size: 16px;
        color: #858faf;
        margin-bottom: 100px;
    }
}
.close_icon{
    position: absolute;
    right: 60px;
    top: 40px;
    color: #7b84a2;
    font-size: 30px;
    cursor: pointer;
    transition: all 0.3s linear;
    &:hover{
        color: #fff;
    }
}

.burger_menu{
    width: 40px;
    height: 24px;
    position: relative;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;
    font-size: 25px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    color: #051441;
    margin-left: 25px;
}

.side_menu_wrapper{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: rgba(5, 20, 65, 0.90);
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    transition: -webkit-transform 0.8s ease .4s;
    transition: transform 0.8s ease .4s;
    transition: transform 0.8s ease .4s, -webkit-transform 0.8s ease .4s;
    -webkit-transform-origin: 0% 50%;
    transform-origin: 0% 50%;
    .side_menu{
        max-width: 545px;
        height: 100%;
        margin-left: auto;
        background: #fff;
        position: relative;
        transition-duration: .4s;
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        opacity: 0;
        ul{
            margin-bottom: 0;
        }
        .menu_top{
            padding: 60px 70px 0px;
            .close{
                font-size: 20px;
            }
        }
        .m_content{
            position: absolute;
            left: 0px;
            top: 10vh;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 55vh;
            -webkit-overflow-scrolling: touch;
            overflow-scrolling: touch;
            font-weight: 600;
            width: 100%;
            display: block;
            letter-spacing: -.025rem;
            list-style: none;
            margin: 0;
            -webkit-transform: translateY(3rem);
            transform: translateY(3rem);
            transition: opacity .5s .6s ease,
            -webkit-transform .5s .6s ease;
            transition: transform .5s .6s ease,
            opacity .5s .6s ease;
            transition: transform .5s .6s ease,
            opacity .5s .6s ease,
            -webkit-transform .5s .6s ease;
            list-style: none;
//            opacity: 0;
            padding-left: 18%;
            padding-right: 100px;
            margin-top: 5vh;
            h2{
                font-size: 3.5vh;
                line-height: 4vh;
                font-weight: 700;
                color: #051441;
                margin-bottom: 1vh;
            }
            a{
                font-size: 2.5vh;
                font-weight: 500;
            }
            p{
                font-size: 1.9vh;
                line-height: 2.9vh;
                color: #7e86a1;
                font-weight: 300;
                margin-top: 4vh;
                margin-bottom: 8.2vh;
            }
        }
        .get_info{
            li{
                .media{
                    img{
                        margin-right: 20px;
                        height: 5vh;
                    }
                    .media-body{
                        h4{
                            font-size: 2vh;
                            font-weight: 600;
                            margin-bottom: 0.5vh;
                        }
                        p{
                            margin-bottom: 0vh;
                            margin-top: 0.5vh;
                            font-size: 1.8vh;
                        }
                    }
                }
                & + li{
                    margin-top: 2.9vh;
                }
            }
        }
        .m_footer{
            position: absolute;
            left: 18%;
            bottom: 10%;
            display: -webkit-flex;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            li{
                a{
                    font-size: 20px;
                    color: #959ebb;
                    font-size: 16px;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    border: 1px solid #959ebb;
                    display: inline-block;
                    margin: 0px 5px;
                    line-height: 50px;
                    border-radius: 50%;
                    transition: all 0.3s linear;
                    &:hover{
                        background: rgb(95, 40, 251);
                        border-color: rgb(95, 40, 251);
                        color: #fff;
                        box-shadow: 0px 20px 20px 0px rgba(95, 40, 251, 0.3);
                    }
                }
            }
        }
    }
}
.menu-is-opened {
    .side_menu_wrapper{
        z-index: 100;
        opacity: 1;
        visibility: visible;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 100% 50%;
        transform-origin: 100% 50%;
        transition: -webkit-transform .7s ease .2s;
        transition: transform .7s ease .2s;
        transition: transform .7s ease .2s, -webkit-transform .7s ease .2s;
        .side_menu{
            opacity: 1;
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            transition: -webkit-transform 0.5s 0.6s linear;
            transition: transform 0.5s 0.6s linear;
            transition: transform 0.5s 0.6s linear, -webkit-transform 0.5s 0.6s linear;
            }
    }
}

/*----------------------------------------------------
@File: Default Styles
@Author: Md. Shahadat Hussain
@URL: https://themeforest.net/user/droitthemes

This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.
---------------------------------------------------- */
/*=====================================================================
@Template Name:
@Author: Md. Shahadat Hussain
@Developed By: Rony
@Developer URL: https://themeforest.net/user/droitthemes

@Default Styles

Table of Content:
01/ variables
02/ predefine
03/ button
04/ preloader
05/ header
06/ banner
07/ breadcrumb
08/ features
09/ service
10/ price
11/ about
12/ testimonial
13/ screenshot
14/ joblist
15/ faq
16/ portfolio
17/ contact
18/ error
19/ shop
20/ blog
21/ footer


=====================================================================*/

html {
scroll-behavior: smooth;
}
strong {
  color: #444;
  border-bottom: 3px solid;
  border-image: linear-gradient(to left, #00a0ff 10%, #002dff 100%);
  border-image-slice: 1;
}

/*====================================================*/
@import "variables";
/*====================================================*/

.startup_fuatures_area {
  h2 {
    letter-spacing: 5px;
    color: $violet;
    text-transform: uppercase;
  }
}


/*====================================================*/
@import "predefine";
/*====================================================*/

/*====================================================*/
@import "button";
/*====================================================*/

/*====================================================*/
@import "preloader";
/*====================================================*/

/*====================================================*/
@import "header";
/*====================================================*/

/*====================================================*/
@import "banner";
/*====================================================*/

/*====================================================*/
@import "breadcrumb";
/*====================================================*/

/*====================================================*/
@import "features";
/*====================================================*/

/*====================================================*/
@import "service";
/*====================================================*/

/*====================================================*/
@import "price";
/*====================================================*/

/*====================================================*/
@import "about";
/*====================================================*/

/*====================================================*/
@import "testimonial";
/*====================================================*/

/*====================================================*/
@import "screenshot";
/*====================================================*/

/*====================================================*/
@import "joblist";
/*====================================================*/

/*====================================================*/
@import "faq";
/*====================================================*/

/*====================================================*/
@import "portfolio";
/*====================================================*/

/*====================================================*/
@import "twentytwenty";
/*====================================================*/

/*====================================================*/
@import "contact";
/*====================================================*/

/*====================================================*/
@import "error";
/*====================================================*/

/*====================================================*/
@import "typography";
/*====================================================*/

/*====================================================*/
@import "blog";
/*====================================================*/

/*====================================================*/
@import "swiper";
/*====================================================*/

/*====================================================*/
@import "shop";
/*====================================================*/

/*====================================================*/
@import "search";
/*====================================================*/

/*====================================================*/
@import "split-home";
/*====================================================*/

/*====================================================*/
@import "hosting";
/*====================================================*/

/*====================================================*/
@import "footer";
/*====================================================*/

/*====================================================*/
@import "navigation";
/*====================================================*/

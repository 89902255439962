
.swiper-features {
   padding: 25px 0 !important;
  &:after {
      content: "";
      height: 100%;
      background: url("../img/new/tab_shape_bg.png") no-repeat scroll center 0 / contain;
      top: 0px;
      right: -55px;
      left: -90px;
      position: absolute;
      z-index: -1;
  }

  .swiper-slide {
    video {
      width: 100%;
    }
    height: initial;
    background-size: cover;
    background-position: center;
  }
  .swiper-pagination-bullet {
    width: 25px !important;
    height: 7px !important;
    border-radius: 25% !important;
  }

  &.swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 45px !important;
  }

  .swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    -webkit-filter: hue-rotate(45deg) !important;
            filter: hue-rotate(45deg) !important;
  }

  .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
    -webkit-filter: hue-rotate(45deg) !important;
            filter: hue-rotate(45deg) !important;
  }

  .swiper-button-prev, .swiper-container-rtl .swiper-button-next {
    -webkit-filter: hue-rotate(45deg) !important;
            filter: hue-rotate(45deg) !important;

  }

  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets {
    -webkit-filter: hue-rotate(45deg) !important;
            filter: hue-rotate(45deg) !important;
  }


}

/*============ hosting_menu css =============*/
.hosting_menu{
    &.menu_four{
        .btn_get{
            background: transparent;
            border-color: #818198;
            color: #fff;
            line-height: 42px;
            &:hover{
                background: #f8636b;
                border-color: #f8636b;
            }
        }
        .w_menu >.nav-item{
            > .nav-link{
                &:before{
                    display: none;
                }
            }
            &:hover,&.active{
                >.nav-link{
                    color: #f8636b;
                }
            }
            &.submenu .dropdown-menu .nav-item{
                &:hover,&:focus,&.active{
                    > .nav-link{
                        color: #f8636b;
                    }
                }
            }
        }
        .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text:hover{
            color: #f8636b;
        }
    }
}
.navbar_fixed{
    .hosting_menu{
        &.menu_four{
            .btn_get{
                background: #f8636b;
                border-color: #f8636b;
                color: #fff;
                &:hover{
                    background: #f8636b;
                    border-color: #f8636b;
                }
            }
        }
    }
}
/*============ hosting_menu css =============*/

/*============ erp_menu css =============*/
.erp_menu{
    .er_btn{
        margin-left: 0;
        margin-top: 0;
    }
    .menu >.nav-item{
        > .nav-link{
            &:before{
                display: none;
            }
        }
        &:hover,&.active{
            >.nav-link{
                color: #4e78f4;
            }
        }
        &.submenu .dropdown-menu .nav-item{
            &:hover,&:focus,&.active{
                > .nav-link{
                    color: #4e78f4;
                }
            }
        }
    }
    .menu > .nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text:hover{
        color: #4e78f4;
    }
}
/*============ erp_menu css =============*/


/*================= hosting_banner_area css ==============*/
.hosting_banner_area{
    background: #020230;
    min-height: 650px;
    height: 100vh;
    display: flex;
    align-items: center;
    position: relative;
}
.hosting_content{
    h2{
        font-size: 50px;
        line-height: 70px;
        color: #fff;
        font-weight: 700;
        margin-bottom: 15px;
    }
    p{
        color: #fff;
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 50px;
    }
    .hosting_btn{
        box-shadow: 0px 10px 50px 0px rgba(248, 99, 107, 0.3);
        &:hover{
            transform: translateY(-5px);
        }
    }
}
.b_line{
    li{
        position: absolute;
        &:nth-child(1){
            bottom: 200px;
            right: 500px;
        }
        &:nth-child(2){
            top: 100px;
            left: 135px;
        }
        &:nth-child(3){
            top: 85%;
            left: 100px;
        }
        &:nth-child(4){
            bottom: 120px;
            right: 750px;
        }
        &:nth-child(7){
            top: 50%;
            right: 200px;
        }
        &:nth-child(6){
            top: 55%;
            left: 195px;
        }
        &:nth-child(5){
            bottom: 150px;
            right: 895px;
        }
        &:nth-child(8){
            top: 220px;
            right: 995px;
        }
    }
}
.hosting_btn{
    background: #f8636b;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    border-radius: 4px;
    padding: 11px 34px;
    box-shadow: 0px 10px 50px 0px rgba(248, 99, 107, 0.3);
    &:hover{
        background: #fe4c55;
    }
}
/*================= hosting_banner_area css ==============*/

/*================= domain_search_area css ==============*/
.domain_search_area{
    background: #fffafa;
    position: relative;
    z-index: 1;
}
.map_bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../img/hosting/map.png") no-repeat scroll center 0 / cover; 
    z-index: -1;
}
.domain_box_info{
    max-width: 1030px;
    margin: 0 auto;
    text-align: center;
    h3{
        font-size: 30px;
        color: #2c2c51;
        font-weight: 600;
        margin-bottom: 40px;
    }
}
.domain_form_inner{
    display: flex;
    justify-content: center;
    border-radius: 8px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 50px 100px 0px rgba(64, 1, 4, 0.1);
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    border-left: 8px solid #f8636b;
    input{
        width: 64%;
        border: 0px;
        border-radius: 0px;
        padding: 0px;
        font-size: 20px;
        font-weight: 300;
        padding-left: 40px;
        @include placeholder{
            color: #9d9db0;
        }
        &:focus{
            outline: none;
            box-shadow: none;
        }
    }
    .domain_select{
        width: 15%;
        .selectpickers{
            border: 0px;
            width: 100%;
            padding: 0px;
            text-align: center !important;
            border-left: 1px solid #d8dcea;
            border-radius: 0px;
            font-size: 20px;
            color: #9d9db0;
            font-weight: 300;
            background: transparent;
            &:after{
                border-bottom: 1px solid #9d9db0;
                border-right: 1px solid #9d9db0;
                right: 35px;
                width: 6px;
                height: 6px;
            }
            ul{
                width: 100%;
                li{
                    color: #9d9db0;
                }
            }
            &:focus{
                outline: none;
                box-shadow: none;
            }
        }
    }
    button{
        width: 21%;
        border: 0px;
        padding: 25px 32px;
        text-transform: uppercase;
        cursor: pointer;
        transition: all 0.3s linear;
        &:before{
            content: "\55";
            font-family: eleganticons;
            transform: rotate(-90deg);
            display: inline-block;
            margin-right: 10px;
        }
    }
}
.domain_price{
    display: inline-flex;
    margin-top: 45px;
    li{
        font-size: 18px;
        font-weight: 500;
        color: #7b7b93;
        & + li{
            margin-left: 50px;
        }
        span{
            font-weight: 400;
            font-size: 16px;
            margin-left: 8px;
        }
    }
}
/*================= domain_search_area css ==============*/
.hosting_service_area{
    position: relative;
}
.hosting_title{
    margin-bottom: 70px;
    h2{
        font-size: 40px;
        line-height: 55px;
        font-weight: 600;
        color: #2c2c51;
        margin-bottom: 15px;
    }
    p{
        color: #7b7b93;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }
    .w_color{
        color: #fff;
    }
}

.hosting_service_item{
    padding: 60px 40px;
    border-top: 6px solid transparent;
    border-radius: 6px;
    transition: all 0.3s linear;
    cursor: pointer;
    .icon{
        width: 70px;
        height: 60px;
        line-height: 60px;
        border-radius: 6px;
        background: #fff7f7;
        text-align: center;
        margin-bottom: 37px;
    }
    p{
        color: #7b7b93;
        margin-bottom: 0;
        font-weight: 400;
    }
    &:hover{
        border-color: #f6626a;
        background: #fff;
        box-shadow: 0px 50px 100px 0px rgba(64, 1, 4, 0.1),0px -6px 0px 0px rgba(248, 99, 107, 0.004);
    }
}
.pattern_shap{
    position: absolute;
    width: 125px;
    height: 225px;
    left: 0;
    top: 200px;
}
.pos_service_info{
    .hosting_service_item{
        padding: 45px 40px;
        h4{
            &:hover{
                color: #5e53fb;
            }
        }
        img{
            margin-bottom: 33px;
        }
        &:hover{
            border-color: #5e53fb;
            box-shadow: 0px 50px 100px 0px rgba(5, 1, 64, 0.1),0px -5px 0px 0px rgba(95, 84, 253, 0.004);
        }
    }
}
.h_head{
    font-size: 20px;
    font-weight: 600;
    color: #2c2c51;
    margin-bottom: 20px;
    transition: all 0.3s linear;
}
a .h_head{
    &:hover{
        color: #f6626a;
    }
}

/*========== hosting_features_area css ========*/
.hosting_features_area{
    padding-bottom: 140px;
}
.h_features_content{
    padding-right: 80px;
    .hosting_title{
        margin-bottom: 40px;
        h2{
            font-weight: 500;
        }
    }
}
.h_features_item{
    padding-right: 150px;
    img{
        margin-right: 20px;
    }
    .h_head{
        margin-bottom: 10px;
    }
    p{
        margin-bottom: 0;
        font-weight: 400;
    }
    & + .h_features_item{
        margin-top: 35px;
    }
}
/*========== hosting_features_area css ========*/

/*========== h_action_area css ========*/
.h_action_area{
    background: #020230;
    padding: 80px 0px;
}
.h_action_content{
    h2{
        color: #fff;
        font-size: 40px;
        font-weight: 600;
        line-height: 54px;
        margin-bottom: 32px;
    }
    p{
        color: #b4b4d3;
        margin-bottom: 42px;
    }
    .hosting_btn{
        
    }
}
/*========== h_action_area css ========*/

/*========== h_blog_area css ========*/
.h_blog_area{
    
}
.h_blog_item{
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.06);
    transition: all 0.2s linear;
    img{
        width: 100%;
    }
    .h_blog_content{
        padding: 25px 40px 30px;
        h3{
            font-size: 24px;
            line-height: 34px;
            font-weight: 500;
            color: #2c2c51;
            transition: color 0.2s linear;
            &:hover{
                color: #f8636b;
            }
        }
        .post_time{
            font-size: 14px;
            color: #7b7b93;
            font-weight: 500;
            text-transform: uppercase;
            margin-bottom: 10px;
            display: inline-block;
            i{
                color: #f8636b;
                margin-right: 5px;
            }
        }
        .post-info-bottom{
            display: flex;
            justify-content: space-between;
            padding-top: 15px;
            .learn_btn_two{
                margin-top: 0;
                font-size: 14px;
                line-height: 16px;
                color: #7b7b93;
                text-transform: uppercase;
                font-weight: 500;
                position: relative;
                &:before{
                    content: "";
                    height: 1px;
                    background: #f8636b;
                    position: absolute;
                    bottom: 0px;
                    width: 0;
                    transition: width 0.2s linear;
                }
                &:hover{
                    color: #f8636b;
                    &:before{
                        width: 100%;
                    }
                    i{
                        padding-left: 5px;
                    }
                }
                i{
                    vertical-align: inherit;
                    padding-left: 0;
                    transition: all 0.2s linear;
                }
            }
            .post-info-comments{
                color: #7b7b93;
                text-transform: uppercase;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                i{
                    color: #f8636b; 
                    padding-right: 3px;
                }
            }
        }
    }
    &:hover{
        box-shadow: 0px 30px 50px 0px rgba(64, 1, 4, 0.08);
    }
}
.pos_blog_item{
    border-radius: 6px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 30px 50px 0px rgba(5, 1, 64, 0.06);
    .h_blog_content{
        padding-left: 35px;
        padding-right: 35px;
        .post_time{
            i{
                color: #5f54fd;

            }
        }
        h3{
            font-size: 20px;
            line-height: 30px;
            &:hover{
                color: #5f54fd;
            }
        }
        .post-info-bottom{
            .learn_btn_two{
                &:before{
                    background: #5f54fd;
                }
                &:hover{
                    color: #5f54fd;
                }   
            }
            .post-info-comments{
                i{
                    color: #5f54fd;
                }
            }
        }
    }
    &:hover{
        box-shadow: 0px 30px 50px 0px rgba(5, 1, 64, 0.08);
    }
}
/*========== h_blog_area css ========*/

.h_action_promo_area{
    background: #f8636b;
    position: relative;
    padding: 130px 0px;
    z-index: 1;
    .overlay_bg{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-attachment: fixed !important;
        z-index: -1;
        opacity: 0.1;
    }
    .hosting_btn{
        background: #fff;
        color: #f8636b;
        box-shadow: 0px 20px 30px 0px rgba(57, 35, 125, 0.1);
        border-radius: 2px;
        padding: 16px 34px;
        margin-top: 10px;
        text-align: center;
        &:hover{
            box-shadow: none;
        }
    }
}
.h_promo_content{
    h2{
        margin-bottom: 0;
        margin-bottom: 10px;
        color: #fff;
        font-size: 40px;
        font-weight: 600;
    }
    p{
        margin-bottom: 0;
        font-size: 20px;
        color: #fff;
        font-weight: 400;
    }
}

/*=========== h_map_area ==========*/
.h_map_area{
    background: #fffafa;
    padding-top: 120px;
    .hosting_title{
        margin-bottom: 0;
    }
}
.h_map{
    background: url('../img/hosting/region_map.png') no-repeat scroll center top;
    height: 595px;
    ul{
        height: 100%;
        margin-bottom: 0;
        position: relative;
        li{
            display: block;
            position: absolute;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            .place_name{
                font-size: 12px;
                font-weight: 500;
                line-height: 25px;
                color: #fff;
                border-radius: 45px;
                background: #f8636b;
                padding: 0px 12px;
                text-transform: uppercase;
                position: absolute;
                top: -100%;
                width: max-content;
                box-shadow: 0px 20px 30px 0px rgba(214, 43, 51, 0.2);
                &:before{
                    content: "";
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 6px 5px 0 5px;
                    border-color: #f8636b transparent transparent transparent;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: -5px;
                }
            }
            .round{
                width:8px;
                height: 8px;
                border-radius: 50%;
                background:#f8636b;
                position: relative;
                &:before,&:after{
                    content: "";
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    border-radius: 50%;
                    background:#f8636b;
                }
                &:before{
                    transform: scale(2.6);
                    opacity: 0.4;
                }
                &:after{
                    transform: scale(5);
                    opacity: 0.2;
                }
                .dot{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    background: rgba(247, 98, 106, 0.35);
                    -webkit-animation: pulsate 3s infinite;
                    animation: pulsate 3s infinite;
                    -webkit-animation-delay: 1.5s;
                    transform: translate(-50%, -50%);
                    animation-delay: 1.5s;
                    will-change: transform;
                }
            }
            &:nth-child(1){
                top: 80px;
                left: 100px;
            }
            &:nth-child(2){
                top: 220px;
                left: 250px;
            }
            &:nth-child(3){
                top: 180px;
                left: 50%;
            }
            &:nth-child(4){
                top: 250px;
                left: 70%;
            }
            &:nth-child(5){
                top: 100px;
                left: 80%;
            }
            &:nth-child(6){
                top: 70%;
                left: 350px;
            }
            &:nth-child(7){
                top: 60%;
                left: 54%;
            }
            &:nth-child(8){
                top: 75%;
                left: 85%;
            }
        }
    }
}

.h_footer_dark{
    background: #010e28;
    padding: 100px 0px 120px;
    .company_widget{
        p{
            font-size: 16px;
            font-weight: 400;
            margin-top: 32px;
            margin-bottom: 0;
        }
        .f_social_icon{
            margin-top: 50px;
            a{
                background: transparent;
                font-size: 14px;
                color: #7b7b93;
                border: 1px solid #2c3445;
                &:hover{
                    background: #f8636b;
                    color: #fff;
                    border-color: #f8636b;
                }
            }
        }
    }
    .f_widget{
        &.about-widget .f_list li a,&.company_widget p{
            color: #7b7b93;
            font-weight: 400;
        }
        &.about-widget .f_list li{
            margin-bottom: 10px;
            a{
                &:before{
                    background: #f8636b;
                }
                &:hover{
                    color: #f8636b;
                }
            }
        } 
    }
}

.h_footer_dark_two{
    .company_widget{
        .f_social_icon{
            a{
                &:hover{
                    background: #4f79f6;
                    border-color: #4f79f6;
                }
            }
        }
    }
    .f_widget{
        &.about-widget .f_list li{
            a{
                &:before{
                    display: none;
                }
                &:hover{
                    color: #4f79f6;
                }
            }
        } 
    }
}


.pos_footer_area{
    background: #413c85;
    padding-bottom: 0;
    padding-top: 120px;
    position: relative;
    z-index: 0;
    .leaf_left,.leaf_right{
        position: absolute;
        bottom: 0;
    }
    .leaf_left{
        left: 0;
    }
    .leaf_right{
        right: 0;
    }
    .top_shap{
        position: absolute;
        bottom: 100%;
        height: 100%;
        left: 0;
        width: 100%;
        align-items: flex-end;
        display: flex;
        z-index: -1;
    }
    &:before{
        content: "";
        width: 100%;
        height: 40px;
        background: #49458c;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &:after{
        content: "";
        width: 100%;
        height: 100%;
        background: url(../img/pos/wave_two.png) no-repeat scroll center bottom;
        position: absolute;
        left: 0;
        top: -100%;
        z-index: -1;
    }
    .f_widget{
        &.company_widget{
            p{
               color: #bab5df; 
            }
            .f_social_icon{
                a{
                    color: #bab5df;
                    border-color: #6e679b;
                    &:hover{
                        background: #fff;
                        border-color: #fff;
                        color: #5f54fd;
                    }
                }
            }
        }
        &.about-widget .f_list li{
            a{
                color: #bab5df;
                &:before{
                    background: #fff;
                }
                &:hover{
                    color: #fff;
                }
            }
        } 
    }
}
.pos_footer_bottom{
    padding-top: 80px;
    padding-bottom: 30px;
    .pos_women{
        position: relative;
        bottom: -12px;
    }
}
.wave_shap{
    width: 100%;
    position: absolute;
    height: 212px;
    bottom: 0;
    z-index: -1;
}

/*============== erp_banner_area_two css =============*/
.erp_banner_area_two{
    background: #f9f9fe;
    position: relative;
    z-index: 0;
    & + section{
        position: relative;
        z-index: 1;
    }
    .animation_img {
        margin-top: -380px;
    }
    .cloud_animation{
        margin-bottom: 0;
        li{
            position: absolute;
            &:nth-child(1){
                top: 220px;
                left: 170px;
                animation: jurk 4s 5s linear infinite alternate;
            }
            &:nth-child(2){
                top: 420px;
                left: 370px;
                animation: jurk 4s 2s linear infinite alternate;
            }
            &:nth-child(3){
                left: 120px;
                top: 50%;
                animation: jurk 3s 3s linear infinite alternate;
            }
            &:nth-child(6){
                top: 220px;
                right: 170px;
                animation: jurk 5s 3s linear infinite alternate;
            }
            &:nth-child(4){
                top: 500px;
                right: 280px;
                animation: jurk 4s 2s linear infinite alternate;
            }
            &:nth-child(5){
                right: 120px;
                top: 380px;
                animation: jurk 8s 2s linear infinite alternate;
            }
        }
    }
    .erp_shap{
        content: '';
        display: block;
        position: absolute;
        top: calc(100% - 190px);
        background-color: white;
        min-height: 390px;
        width: 2000px;
        left: -77px;
        -webkit-transform: rotate(-6deg);
        -webkit-transform-origin: 0 0 0;
        -moz-transform: rotate(-6deg);
        -moz-transform-origin: 0 0 0;
        -o-transform: rotate(-6deg);
        -o-transform-origin: 0 0 0;
        -ms-transform: rotate(-6deg);
        -ms-transform-origin: 0 0 0;
        transform: rotate(-6deg);
        z-index: -1;
    }
    .erp_shap_two{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -2;
    }
    .animation_img{
        img{
            box-shadow: 0px 40px 100px 0px rgba(1, 1, 64, 0.08);
        }
    }
    .section_container{
        .intro_content{
            h1{
                font-size: 50px;
                line-height: 64px;
                color: #2c2c51;
                font-weight: 600;
            }
            p{
                font-size: 18px;
                font-weight: 400;
                margin-bottom: 40px;
            }
            .er_btn{
                padding: 10px 37px;
                margin-top: 0;
            }
        }
    }
}
@keyframes cloud {
    0% {
        left: 14%;
		top: 200px;
		opacity: 0;
    }
    20% {
        left: 44%;
		top: 100px;
		opacity: 1;
    }
    40% {
        left: 65%;
		top: 50px;
		opacity: 1;
    }
    60% {
        left: 80%;
		top: 130px;
		opacity: 1;
    }
    80% {
        left: 90%;
		top: 180px;
		opacity: .5;
    }
    100% {
        left: 100%;
		top: 230px;
		opacity: 0;
    }
}

@keyframes animateCloud {
    0% {
        left: -20%;
        opacity: 1;
    }
    100% {
        left: 100%;
        opacity: 1;
    }
}
@keyframes jurk {
    0% {
        transform: translate3d(-10px, -10px, 0);
		opacity: 1;
    }
    50% {
        transform: translate3d(-20px, 20px, 0);
		opacity: 1;
    }
    100% {
        transform: translate3d(30px, 0px, 0);
		opacity: 1;
    }
}

/*============== erp_banner_area_two css =============*/


/*============== erp_service_area css =============*/
.erp_service_area{
    padding-top: 120px;
    .row{
        margin-bottom: -100px;
    }
}
.erp_title{
    h2{
        font-size: 34px;
        span{
            color: #ff0000;
        }
    }
}
.erp_service_item{
    margin-bottom: 100px;
    img{
        margin-bottom: 35px;
    }
    .h_head{
        &:hover{
            color: #4e78f4;
        }
    }
    p{
        font-weight: 400;
        margin-bottom: 0;
    }
}
/*============== erp_service_area css =============*/
.erp_action_area{
    background: #4e78f4;
    padding: 58px 0px;
}
.erp_content{
    h2{
        color: #fff;
        font-size: 40px;
        line-height: 54px;
        letter-spacing: 0.25px;
        font-weight: 300;
        strong{
            font-weight: 700;
        }
    }
}

.h_pricing_area{
    
}
.h_price_inner{
    max-width: 970px;
    margin: 0 auto;
    border: 2px solid #f7f0f0;
}
.hosting_tab{
    display: flex;
    .nav-item{
        width: calc(100% / 3);
        .nav-link{
            text-align: center;
            font-size: 16px;
            font-weight: 500;
            color: #7b7b93;
            background: #faf7f8;
            border-radius: 0px;
            border: 0px;
            line-height: 64px;
            border-left: 2px solid #f7f0f0;
            border-bottom: 2px solid #f7f0f0;
            &.active{
                background: #fff;
                border-bottom-color: #fff;
            }
        }
        &:first-child{
            .nav-link{
                border-left: 0px;
            }
        }
    }
}
.h_price_tab{
    p{
        font-size: 16px;
        color: #7b7b93;
        padding: 50px;
        font-weight: 400;
        margin-bottom: 0;
    }
}
.h_price_body{
    .price_head{
        display: table;
        width: 100%;
        background: #faf7f8;
        height: 50px;
        padding-right: 50px;
        border-top: 1px solid #f7f0f0;
        .p_head{
            width: calc(100% / 6);
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            h5{
                font-size: 14px;
                font-weight: 500;
                color: #2c2c51;
                margin-bottom: 0;
                text-transform: uppercase;
            }
        }
    }
    .h_p_list{
        display: table;
        width: 100%;
        padding-right: 50px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-top: 1px solid #f7f0f0;
        transition: all 0.2s linear;
        cursor: pointer;
        .h_price_item{
            width: calc(100% / 6);
            text-align: center;
            vertical-align: middle;
            display: table-cell;
            h5{
                margin-bottom: 0;
                font-size: 16px;
                color: #7b7b93;
                font-weight: 400;
                span{
                    font-weight: 600;
                    display: block;
                }
            }
            &.memory{
                h5{
                    font-weight: 600;
                }
            }
            .h_price_btn{
                border: 1px solid #f8c0c3;
                font-size: 14px;
                font-weight: 500;
                color: #f8636b;
                border-radius: 4px;
                padding: 11px 20px;
                transition: all 0.3s linear;
                display: inline-block;
                &:hover{
                    background: #f8636b;
                    border-color: #f8636b;
                    color: #fff;
                }
            }
        }
        &:hover{
            background: #fdfafa;
        }
    }
}

/*=========== erp_features_area css =============*/
.erp_features_area{}
.erp_item_features{
    & + .erp_item_features{
        margin-top: 200px;
    }
}
.erp_features_img_two{
    position: relative;
    padding-top: 50px;
    .img_icon{
        width: 110px;
        height: 110px;
        border-radius: 50%;
        color: #fff;
        line-height: 112px;
        font-size: 24px;
        text-align: center;
        position: absolute;
        top: 0px;
        right: -55px;
        z-index: 1;
        .pluse_1,.pluse_2{
            content: '';
            width: 100%;
            height: 100%;
            position: absolute;
            border-radius: 50%;
            z-index: -1;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: rgba(243 ,186, 14,.102);
            &:before,&:after{
                content: '';
                position: absolute;
                background: rgba(243 ,186, 14,.13);
                border-radius: 50%;
                left: 50%;
                top: 50%;
                margin-left: -40px;
                margin-top: -40px;
            }
            &:before{
                width: 80px;
                height: 80px; 
            }
            &:after{
                width: 60px;
                height: 60px;
                background: rgba(243 ,186, 14, 1);
                margin-left: -30px;
                margin-top: -30px;
            }
        }
        &.red{
            .pluse_1,.pluse_2{
                background: rgba(249, 62, 94, .102);
                &:before,&:after{
                    background: rgba(249, 62, 94, .13);
                }
                &:after{
                    background: rgba(249, 62, 94, 1);
                }
            }
        }
        &.green{
            .pluse_1,.pluse_2{
                background: rgba(112, 215, 21, .102);
                &:before,&:after{
                    background: rgba(112, 215, 21, .13);
                }
                &:after{
                    background: rgba(112, 215, 21, 1);
                }
            }
        }
        .pluse_1{
            animation: pulsates 3s infinite linear;
        }
        .pluse_2{
            background: transparent !important;
            &:before{
                animation: pulsates 3s infinite linear;
                animation-delay: 1s;
                margin-left: 0;
                margin-top: 0;
            }
        }
    }
    img{
        box-shadow: 0px 25px 100px 0px rgba(1, 1, 64, 0.1);
    }
}
@keyframes pulsates{
    0%{
        opacity:1;
        transform:translate(-50%, -50%) scale(0.3);
    }
    100%{
        opacity:0;
        transform:translate(-50%, -50%) scale(2);
    }
}
.erp_content_two{
    padding-left: 120px;
    .erp_title{
        margin-bottom: 40px;
        h2{
            line-height: 44px;
            margin-bottom: 20px;
        }
    }
    .erp_item{
        align-items: center;
        margin-top: 28px;
        .icon{
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: rgb(249, 62, 94);
            box-shadow: 0px 20px 30px 0px rgba(172, 20, 46, 0.24);
            text-align: center;
            font-size: 20px;
            line-height: 52px;
            color:#fff;
            margin-right: 20px;
            &.green{
                background: #70d715;
                box-shadow: 0px 20px 30px 0px rgba(91, 171, 21, 0.24);
            }
            &.blue{
                background: #3e89f9;  
                box-shadow: 0px 20px 30px 0px rgba(21, 81, 171, 0.24);
            }
            &.yellow{
                box-shadow: 0px 20px 30px 0px rgba(171, 133, 21, 0.24);
                background: #f3ba0e;
            }
            &.purple{
                box-shadow: 0px 20px 30px 0px rgba(138, 21, 171, 0.24);
                background: #d02cff;
            }
            &.green_two{
                box-shadow: 0px 20px 30px 0px rgba(21, 168, 171, 0.24);
                background: #17d2d4;
            }
        }
        .media-body{
            h5{
                margin-bottom: 0;
                font-size: 18px;
                line-height: 22px;
                color: #2c2c51;
            }
        }
    }
    .erp_btn_learn{
        font-size: 16px;
        line-height: 20px;
        color: #4e78f4;
        font-weight: 500;
        display: inline-block;
        margin-top: 40px;
        i{
            vertical-align: middle;
            padding-left: 8px;
        }
    }
}
.flex-row-reverse{
    .erp_features_img_two{
        .img_icon{
            left: -53px;
            right: auto;
        }
    }
    .erp_content_two{
        padding-right: 125px;
        padding-left: 0;
    }
}

/*=========== erp_analytics_area css ============*/
.erp_analytics_area{
    background: url("../img/erp-home/background.png") no-repeat scroll center 0 /cover;
    padding: 100px 0px 220px;
}
.erp_analytics_item{
    padding: 0px 50px;
    p{
        font-size: 22px;
        color: #2c2c51;
        font-weight: 500;
        margin-bottom: 0;
        margin-top: 25px;
    }
}

.erp_testimonial_area{
    position: relative;
    z-index: 2;
    .row{
        margin-right: -25px;
        margin-left: -25px;
    }
}
.er_btn{
    border: 2px solid #c8d4fa;
    font-size: 16px;
    font-weight: 500;
    color: #4e78f4;
    font-family: $pop;
    border-radius: 45px;
    line-height: 26px;
    padding: 8px 27px;
    display: inline-block;
    margin-top: 60px;
    transition: all 0.3s linear;
    &:hover{
        background: #4e78f4;
        border-color: #4e78f4;
        box-shadow: 0px 20px 30px 0px rgba(45, 93, 237, 0.1);
        color: #fff;
    }
}
.erp_testimonial_info{
    position: relative;
    .owl-nav{
        button{
            &.owl-prev,&.owl-next{
                position: absolute;
                top: 50%;
                transform: translateY(-50%); 
                font-size: 45px;
                color: #c1c1d4;
                transition: all 0.2s linear;
                &:hover{
                    color: #4e78f4;
                }
            }
            &.owl-prev{
                left: -50px;
            }
            &.owl-next{
                right: -50px;;
            }
        }
    }
}
.erp_testimonial_item{
    padding: 10px 25px;
    .content{
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 20px 40px 0px rgba(1, 1, 64, 0.06);
        padding: 50px;
        position: relative;
        &:before{
            content: "";
            width: 14px;
            height: 14px;
            background: #fff;
            transform: rotate(45deg);
            position: absolute;
            bottom: -8px;
            left: 70px;
        }
        p{
            font-weight: 400;
            font-size: 20px;
            color: #7b7b93;
            line-height: 36px;
        }
    }
    .ratting{
        padding-top: 30px;
        a{
            font-size: 15px;
            color: #f5c10d;
        }
    }
    .media{
        align-items: center;
        margin-top: 30px;
        padding-left: 50px;
        img{
            width: auto;
            border-radius: 50%;
            margin-right: 20px;
        }
        .media-body{
            h5{
                font-size: 18px;
                font-weight: 500;
                color: #2c2c51;
                margin-bottom: 3px;
                span{
                    color: #7b7b93;
                    font-weight: 400;
                    font-size: 16px;
                }
            }
            p{
                margin-bottom: 0;
                color: #7b7b93;
                font-weight: 400;
            }
        }
    }
}

.erp_call_action_area{
    padding-bottom: 120px;
    padding-top: 50px;
    background: #fafafd;
    position: relative;
    z-index: 1;
    &:before{
        content: "";
        position: absolute;
        background: url("../img/erp-home/shape.png") no-repeat scroll center bottom / cover;
        width: 100%;
        left: 0;
        height: 100%;
        z-index: -1;
    }
    &:after{
        content: '';
        display: block;
        position: absolute;
        z-index: 10;
        top: -80%;
        background-color: #fafafd;
        min-height: 560px;
        width: 2000px;
        -webkit-transform: rotate(-6.8deg);
        -ms-transform: rotate(-6.8deg);
        transform: rotate(-6.8deg);
        left: -1.5%;
        z-index: -2;
    }
}
.erp_action_content{
    img{
        margin-bottom: 32px;
    }
    h3{
        font-size: 40px;
        font-weight: 600;
        color: #2c2c51;
        line-height: 44px;
        margin-bottom: 18px;
    }
    p{
        font-size: 24px;
        color: #7b7b93;
        font-weight: 400;
        span{
            font-weight: 700;
            color: #4e78f4;
        }
    }
    .er_btn{
        margin-top: 40px;
    }
}
.er_btn_two{
    background: #4e78f4;
    border-color: #4e78f4;
    color: #fff;
    box-shadow: 0px 20px 30px 0px rgba(45, 93, 237, 0.1);
    &:hover{
        box-shadow: none;
        border-color: #c8d4fa;
        background: transparent;
        color: #4e78f4;
    }
}

.erp_customer_logo_area{
    padding-top: 120px;
    .er_btn{
        margin-top: 140px;
    }
}
.animation_inner{
    height: 370px;
    position: relative;
    li{
        border-radius: 10px;
        box-shadow: 0px 2px 4px 0px rgba(2, 45, 79, 0.1);
        position: absolute;
        transform: matrix(0.97029, 0.34192, -0.84486, 0.9425, 0, 0);
        img{
            border-radius: 10px;
        }
        &:nth-child(1){
            top: 0;
            left: 35px;
            box-shadow: 0px 30px 30px 0px rgba(2, 45, 79, 0.1);
        }
        &:nth-child(2){
            top: 55px;
            left: 170px;
        }
        &:nth-child(3){
            top: 130px;
            left: 270px;
        }
        &:nth-child(4){
            top: 35px;
            left: 410px;
        }
        &:nth-child(5){
            top: 109px;
            left: 495px;
        }
        &:nth-child(6){
            top: 40px;
            left: 700px;
        }
        &:nth-child(7){
            top: 0;
            left: 890px;
        }
        &:nth-child(8){
            top: 40%;
            left: 35px;
        }
        &:nth-child(9){
            top: 182px;
            left: 585px;
            box-shadow: 10px 30px 30px 0px rgba(2, 45, 79, 0.15);
        }
        &:nth-child(10){
            top: 130px;
            left: 780px;
        }
        &:nth-child(11){
            top: 128px;
            left: 950px;
        }
        &:nth-child(12){
            top: 85px;
            left: 95%;
        }
        &:nth-child(13){
            bottom: 0;
            left: -35px;
        }
        &:nth-child(14){
            bottom: 30px;
            left: 150px;
            box-shadow: 10px 30px 30px 0px rgba(2, 45, 79, 0.15);
        }
        &:nth-child(15){
            bottom: 20px;
            left: 310px;
        }
        &:nth-child(16){
            bottom: -25px;
            left: 460px;
        }
        &:nth-child(17){
            bottom: 35px;
            left: 690px;
        }
        &:nth-child(18){
            bottom: -30px;
            left: 800px;
        }
        &:nth-child(19){
            bottom: 45px;
            left: 940px;
        }
        &:nth-child(20){
            bottom: 0px;
            right: 0;
        }
    }
}

/*============= pos_banner_area css ===========*/
.pos_banner_area{
    height: 100vh;
    min-height: 650px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}
.pos_slider{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    &:before{
        content: "";
        width: 100%;
        height: 100%;
        background: #1a1a3a;
        opacity: 0.70;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
    }
    .owl-stage-outer,.owl-stage,.owl-item{
        height: 100%;
        .pos_banner_item{
            background-size: cover !important;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}
.pos_banner_text{
    max-width: 820px;
    margin: 0 auto;
    h6{
        font-size: 14px;
        line-height: 30px;
        font-family: $pop;
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: 4px;
        margin-bottom: 12px;
    }
    h6,h2{
        color: #fff;
    }
    h2{
        font-size: 50px;
        font-weight: 400;
        color: #fff;
        span{
            font-weight: 700;
        }
    }
    .action_btn {
        margin-top: 40px;
    }
    .software_banner_btn{
        border-radius: 6px;
        background: #5f54fd;
        padding: 14px 42px;
        border: 1px solid #5f54fd;
        transition: all 0.2s linear;
        &:hover{
            background: rgba(255, 255, 255, 0.1);
            border-color: #fff;
            color: #fff;
        }
    }
    .video_btn{
        .icon{
            box-shadow: none;
            height: 50px;
            width: 50px;
            color: #fff;
            border: 1px solid #fff;
            background: rgba(255, 255, 255, 0.20);
            font-size: 14px;
            line-height: 48px;
            margin-right: 13px;
        }
        span{
            &:before{
                display: none;
            }
        }
    } 
}
/*============= pos_banner_area css ===========*/

.ticket_area{
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    .ticket_shap{
        content: "";
        width: 100%;
        height: 730px;
        position: absolute;
        right: 0;
        top: 0;
        background-position: right, right !important;
        background-repeat: no-repeat !important;
        
    }
    .pattern{
        position: absolute;
        right: 0;
        top: 0;
        width: 225px;
        height: 125px;
    }
    .container{
        display: flex;
        flex-wrap: wrap;
    }
}
.ticket_item{
    background: #fff;
    padding: 30px 50px 70px;
    box-shadow: 0px 50px 100px 0px rgba(5, 1, 64, 0.1);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    display: inline-block;
    width: calc(84% / 3);
    position: relative;
    &:before{
        content: "";
        width: 100%;
        height: 10px;
        background: url("../img/pos/shape_bg.png") no-repeat scroll center bottom / cover;
        position: absolute;
        bottom: -10px;
        left: 0;
    }
    &:after{
        bottom: 40px;
        content: "";
        position: absolute;
        left: -3px;
        width: 100%;
        height: 1px;
        background-image: linear-gradient(to right, transparent 50%, #c9c9d6 50%);
        background-size: 10px 94%;
    }
    .icon{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        line-height: 60px;
        text-align: center;
        background: #edfcec;
        margin-bottom: 25px;
        &.pink{
            background: #feecfb;
        }
        &.yellow{
            background: #fefaec;
        }
    }
    h2{
        font-size: 44px;
        line-height: 35px;
        font-weight: 600;
        color: #2c2c51;
        text-transform: uppercase;
        span{
            font-weight: 500;
            color: #7b7b93;
            font-size: 18px;
            line-height: 30px;
            display: block;
            padding-top: 10px;
        }
    }
    p{
        margin-bottom: 0;
        margin-top: 25px;
        font-size: 14px;
        line-height: 30px;
        letter-spacing: 4px;
        color: #aaaabb;
        text-transform: uppercase;
        margin-bottom: 0;
        font-weight: 500;
    }
    & + .ticket_item{
        margin-left: 8%;
    }
}

.pos_developer_product_area{
    background: #fafafc;
    .tab_img_info{
        z-index: 1;
        .tab_img{
            padding-bottom: 30px;
            overflow: visible;
            margin-left: -50px;
            img{
                position: relative;
                z-index: 1;
                opacity: 0;
                transform: scale(0.9);
                transition: all 0.9s linear, opacity 0.2s linear;
            }
            .square{
                height: 100%;
                width: 0px;
                right: 90px;
                top: 0;
                background: #f3f3f9;
                z-index: 0;
                opacity: 0;
                transition: width 0.5s linear;
            }
            .bg_circle{
                width: 220px;
                background: #f4cfe5;
                height: 220px;
                border-radius: 50%;
                position: absolute;
                bottom: 15px;
                left: 5px;
                z-index: 0;
                transform: scale(0.5);
                opacity: 0;
                transition: all 0.7s linear;
                &.green{
                   background: #c8ede9; 
                }
                &.pink{
                   background: #f93e5e; 
                }
                &.yellow{
                   background: #f9edcf; 
                }
            }
            .pattern_shap{
                top: auto;
                bottom: -30px;
                left: 100px;
                z-index: -1;
            }
            &.active{
                img{
                    transform: scale(1);
                    opacity: 1;
                }
                .square{
                    opacity: 1;
                    width: 350px;
                    transform: translateX(0);
                }
                .bg_circle{
                    opacity: 1;
                    transform: scale(1);
                }
            }
            .tab_round{
                width: 30px;
                height: 30px;
                border-radius: 50%;
                border: 5px solid #f9edcf;
                position: absolute;
                top: 100px;
                left: -20px;
            }
            .tab_triangle{
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 12.5px 0 12.5px 20px;
                border-color: transparent transparent transparent #c8ede9;
                position: absolute;
                top: -15px;
                left: 170px;
                border-radius: 4px;
            }
        }
    }
    .developer_product_content{
        padding-right: 0px;
        .develor_tab{
            padding-left: 0;
            margin-bottom: 50px;
            .nav-item{
                .nav-link{
                    color: #2c2c51;
                    &:before{
                        background: #5f54fd;
                    }
                    &.active{
                        color: #5f54fd;
                    }
                }
            }
        }
        h4{
            font-size: 30px;
            line-height: 40px;
            color: #2c2c51;
            font-weight: 600;
            margin-bottom: 32px;
        }
        p{
            font-size: 16px;
            line-height: 28px;
            color: #7b7b93;
            margin-bottom: 0;
        }
    } 
}


.pos_features_area{
}
.pos_item{
    & + .pos_item{
        margin-top: 150px;
    }
}
.flex-row-reverse{
    .pos_features_content{
        padding-right: 70px;
    }
    .pos_features_img{
    }
}
.pos_features_content{
    padding-top: 50px;
    h2{
        font-size: 34px;
        color: #2c2c51;
        line-height: 44px;
        font-weight: 500;
        margin-bottom: 50px;
    }
    .h_features_item{
        padding-right: 0;
        i{
            font-size: 24px;
            color: #bb3efc;
            margin-right: 15px;
            &.orange{
                color: #fb702b;
            }
            &.green{
                color: #0ed1ca;
            }
            &.red{
                color: #f826c0;
            }
            &.check{
                color: #1ad90b;
            }
        }
        .h_head{
            font-weight: 500;
        }
        p{
            color: #7b7b93;
        }
    }
}
.pos_features_img{
    padding: 40px 0px 80px;
    position: relative;
    text-align: center;
    .shape_img{
        width: 470px;
        background: #e0faf4;
        border-radius: 6px;
        height: 100%;
        position: absolute;
        right: -50px;
        top: 0;
        z-index: -1;
        &.yellow{
            background: #fff7e5;
        }
        &.blue{
            background: #e5f6ff;
        }
    }
    .shap_img{
        background: #99edd9;
        border-radius: 6px;
        height: 420px;
        display: flex;
        align-items: center;
        justify-content: center;
        &.yellow{
            background: #ffe3a9;
        }
        &.blue{
            background: #a9e2ff;
        }
    }
    &.img_left{
        .shape_img{
            left: -50px;
            right: auto;
        }
    }
}


.pos_about_area{
    background: #413c85;
    padding-top: 120px;
    display: flex;
    & + section{
        padding-top: 200px;
    }
}
.pos_about_img{
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 82px;
    margin-bottom: -140px;
}
.pos_about_list{
    column-count: 3;
    margin-bottom: 65px;
    li{
        font-size: 16px;
        color: #d0cfe6;
        line-height: 28px;
        position: relative;
        padding-left: 40px;
        &:before{
            content: "\4e";
            width: 10px;
            height: 10px;
            position: absolute;
            left: 0;
            top: 0;
            color: #1fc61a;
            font-size: 24px;
            font-family: eleganticons;
        }
        & + li{
            margin-top: 40px;
        }
    }
}
.pos_subscribe{
    .form-group{
        margin-bottom: 0;
        input{
            width: 100%;
            border: 0px;
            height: 60px;
            border-radius: 5px;
            font-size: 16px;
            color: #2c2c51;
            padding-left: 30px;
            @include placeholder{
                color: #bbbbce;
            }
        }
    }
    .btn_pos{
        background: #5f54fd;
        font-size: 16px;
        font-weight: 500;
        padding: 17px 37px;
        color: #fff;
        &:hover{
            background: #6b61fc;
            color: #fff;
        }
    }
}


.chat_area{
    padding: 60px 0px 150px;
    position: relative;
    z-index: 1;
}
.chat_content{
    padding-top: 40px;
    h2{
        font-size: 34px;
        color: #2c2c51;
        font-weight: 500;
        line-height: 44px;
    }
    .pos_btn{
        margin-top: 45px;
    }
}
.chat_info{
    background: #fafaff;
    border-radius: 6px;
    height: 100%;
    align-items: center;
    padding: 77px 45px;
    border-left: 6px solid #5f54fd;
    img{
        margin-right: 46px;
    }
    .date{
        font-size: 40px;
        font-weight: 700;
        color: #5f54fd;
        padding-bottom: 32px;
        span{
            font-weight: 400;
        }
    }
    h3{
        font-size: 26px;
        line-height: 36px;
        color: #2c2c51;
        font-weight: 500;
    }
    .pos_service_btn{
        margin-top: 32px;
    }
}
.pos_service_btn{
    font-size: 16px;
    font-weight: 500;
    color: #5f54fd;
    line-height: 18px;
    display: inline-block;
    position: relative;
    &:before{
        content: "";
        width: 0;
        height: 1px;
        background: #5f54fd;
        position: absolute;
        bottom: 0;
        left: 0;
        transition: width 0.3s linear;
    }
    i{
        vertical-align: middle;
        padding-left: 10px;
        transition: all 0.2s linear;
    }
    &:hover{
        color: #5f54fd;
        &:before{
            width: 100%;
        }
        i{
            padding-left: 15px;
        }
    }
}
.pos_btn{
    font-size: 16px;
    color: #5f54fd;
    font-weight: 500;
    padding: 9px 24px;
    border: 2px solid #aba6f9;
    border-radius: 4px;
    display: inline-block;
    transition: all 0.3s linear;
    &:hover{
        background: #5f54fd;
        color: #fff;
        border-color: #5f54fd;
        box-shadow: 0px 10px 30px 0px rgba(95, 84, 253, 0.15);
    }
}
.pos_action_area{
    position: relative;
    z-index: 3;
    margin-bottom: -30px;
    .pos_action_content {
        border-radius: 20px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 30px 80px 0px rgba(5, 1, 64, 0.1),0px -4px 0px 0px rgba(95, 84, 253, 0.004);
        border-top: 4px solid #5d52f9;
        padding: 77px 20px;
        h2{
            font-size: 40px;
            color: #2c2c51;
            line-height: 44px;
            font-weight: 600;
            margin-bottom: 15px;
        }
        p{
            font-size: 24px;
            line-height: 35px;
            font-weight: 400;
            color: #7b7b93;
            margin-bottom: 52px;
        }
        .pos_btn{
            background: #5f54fd;
            color: #fff;
            border-color: #5f54fd;
            padding: 7px 33px;
        }
    }
}

.menu_pos{
    .container{
        position: relative;
        .search_cart {
            background: transparent;
        }
        .menu_toggle .hamburger span,.menu_toggle .hamburger-cross span{
            background: #fff;
        }
    }
}